import { common } from "./common";
import { MIN_LICENSES } from "@/helpers/variables/backendConstants";

export default {
  languages: {
    ptBR: "Portuguese",
    en: "English",
    es: "Spanish",
  },

  tour: {
    vstep99:
      "Easily create your email signature with a ready-made and fully customizable template.",
    vstep99Title: "Start with a template!",
    welcome: "Welcome to",
    startTour: "Start tour",
    skipTour: "Continue without tour",
  },

  TRIAL: "trial",
  FLEX: "month",
  ANNUAL: "year",

  action: {
    export: "Export",
    knowPlans: "I want to know the plans",
    talkToTeam: "Talk to the team",
    seePlans: "view plans",
    cancel: "Cancel",
    buy: "Buy",
    confirm: "Confirm",
    clearFields: "Clear fields",
    clear: "Clear",
    save: "Save",
    saveChanges: "Save changes",
    saveOnly: "Save only",
    saveAndReapply: "Save and reapply",
    reportError: "Report error",
    ok: "OK",
    skip: "Skip",
    continue: "Continue",
    search: "Search",
    knowMore: "Know more",
    addGroup: "Add group",
    close: "close",
    delete: "Delete",
    edit: "Edit",
    makeDefault: "Make default",
    next: "Next",
    previous: "Previous",
    clickingHere: "Clicking here",
    saveAndNext: "Save and next",
    applyAndNext: "Apply and next",
    reloadUserInfo: "Reload information",
    assignLicense: "Assign license",
    loginGoogle: "Login with Google",
    loginMicrosoft: "Login with Microsoft",
    aboutScopes: "Understand the requested scopes",
    installApp: "Install the App on your Google Workspace",
    acceptScopes: "Accept scopes",
    minimize: "Minimize",
    finish: "Finish",
    viewAndSend: "View and send",
    saveAndReapplySignature: "Save and reapply signature",
    sync: "Sync",
    disconnect: "Disconnect",
    create: "Create",
    newWorkspaceGroup: "New workspace group",
    transferFilesFromDrive: "Transfer files from Drive",
    migrateEmails: "Migrate emails",
    updateUsersViaCSV: "Update users via CSV",
    download: "Download",
    clearSelection: "Clear selection",
    apply: "Apply",
    requestUpdateUsersInfo: "Request users to update their Information",
    requestUsersUpdateInfoAndApply:
      "Request users to apply the signature model and update their Information",
    calendarsToUsers: "Calendars to users",
    removeVacationMessage: "Remove vacation messages",
    selectAllUsersOnThisPage: "Select all users on this page",
    send: "Send",
    update: "Update",
    preview: "Preview",
    transferAndNext: "Transfer and Next",
    applyModel: "Apply template",
    start: "Start",
    understand: "Understand",
    reloadShifts: "Reload shifts",
    createShifts: "Create shifts",
    applyVacationMessage: "Apply message",
    applySignature: "Apply signature",
    configVacationMessage: "Configure vacation",
  },

  common,

  scopesDialog: {
    title:
      "To complete your login, please accept all Conecta Suite permissions in the Google Admin Console.",
    permissions: "We need these permissions to provide a complete experience.",
    privacy:
      "Your privacy and security are our priorities, and you can review the permissions at any time.",
    faq: `If you have any questions, visit the <a href="https://conectasuite.com/perguntas-frequentes/escopos-google-workspace/" target="_blank"><b>FAQ</b></a> or contact us.`,
    acceptAll: `Go to the Google Admin Console and click on "Allow access" to proceed.`,
    goToAdminConsole: "Go to Google Admin Console",
    goToPanel: "Go to the panel",
  },

  companyProfile: {
    companyProfile: "Company profile",
    companyInfo: "Company Information",
    companyName: "Company Name",
    cnpj: "CNPJ",
    email: "Email",
    site: "Site",
    phone: "Phone",
    phoneNumber: "Phone number",
    phoneHint:
      "Please enter a phone number, preferably with the country code, area code, and number.",
    mainPhone: "Main phone",
    corporate: "Legal Entity Name",
    financeEmail: "Finance Email",
    adminEmail: "Admin Email",
    neighborhood: "Neighborhood",
    address: "Address",
    number: "Number",
    complement: "Complement",
    city: "City",
    state: "State",
    country: "Country",
    zipCode: "Zip Code",
    partner: "Current Partner",
    partnerCode: "Partner Code",
    uploadLogo: "Change company logo",
    uploadBanner: "Change company banner",
    language: "Language",
    completeAddress: "Complete Address",
    addressExample: "St. Example name, 1234",
    addressHint:
      "Enter the address in full and the number separated by a comma.",
    reapplyUnavailablePlan:
      "Email signature reapplication is only available for Standard + plans. Purchase now to use it.",
    reapplyUnavailablePermission:
      "You don't have permission to manage email signatures. Ask the administrator to grant your access!",
    reapplyDialogTitle:
      "Do you want to reapply the signature models with the new values?",
    reapplyDialogSubtitle:
      "The changes will be applied to the models listed below:",
    reapplyHint:
      "<b>Tip:</b> You can also manage which templates should be reapplied by adding or removing them from the field below:",
    affectedModels: "Affected models",
    signatureModelsAlert:
      "Signatures models can take up to 10 minutes to effectively apply.",
    noSignatureModelsAffected:
      "There are no models affected by the changed information.",
    confirmInfoChanges: "Confirm information changes?",
    companyInfoTitle: "Company Information",
    companyInfoSubtitle: "Update and view company information.",
    companyContactInfoTitle: "Contact Information",
    companyContactInfoSubtitle: "Update contact information and social media.",
    companyMarketingInfoTitle: "Marketing Information",
    companyMarketingInfoSubtitle: "Set up banner, slogan, and more.",
    companyCustomInfoTitle: "Custom Information",
    companyCustomInfoSubtitle: "Add any information you want.",
    companyBillingTitle: "Billing",
    companyBillingSubtitle: "Manage your payment details.",
    companyPlansInfoTitle: "Plans and Billing",
    companyPlansInfoSubtitle: "Check your plan and billing history.",
    contactInfo: "Contact information and social media",
    companyContactTags: {
      phone2: "Phone 2",
      phone3: "Phone 3",
      cellphone: "Cellphone",
      social_media_whatsapp: "WhatsApp",
      social_media_facebook: "Facebook",
      social_media_linkedin: "LinkedIn",
      social_media_twitter: "X (Twitter)",
      social_media_youTube: "YouTube",
      social_media_instagram: "Instagram",
    },
    marketingInfo: "Marketing Information",
    companyMarketingTags: {
      banner_link: "Banner link",
      slogan: "Slogan",
      trading_name: "Trading Name",
    },
    companyMarketingTagsHint: {
      banner_link:
        "Add a link to where the banner should redirect when clicked.",
    },
    customInfo: "Custom Information",
    customInfoTooltip:
      "Add personalized information to use in your signature templates",
    addNewInfo: "Add new information",
    customInfoExceeded: "Custom information limit exceeded",
    customInfoTags: {
      company_custom_1: "Custom information 1",
      company_custom_2: "Custom information 2",
    },
    newCustomTag: "New custom information",
    deleteCustomTag: "Delete custom information",
    confirmDeleteCustomTag:
      "Do you want to confirm the deletion of the <strong>{tag}</strong> information?",
    billingInfo: "Billing Information",
    billingHistory: "Billing history",
  },

  plansStatus: {
    ACTIVE: "Active",
    TRIAL: "Trial",
    TRIAL_EXPIRED: "Expired",
    DISABLED: "Disabled",
    EXPIRED: "Expired",
    SUSPENDED: "Suspended",
    CANCELLED: "Canceled",
    CANCELLATION_DATE: "Canceled",
  },

  planPeriodDescription: {
    ANNUAL: "annual",
    FLEX: "monthly",
    QUARTELY: "quarterly",
    SEMESTER: "semester",
    BIENNIUM: "biennial",
    EXTRA: "extra licenses",
    FREE: "free",
    TRIAL: "trial",
    ONCE: "once",
  },

  companyPlansInfo: {
    myPlans: "My plans and services",
    knowHowToPurchase: "Learn how to purchase a plan for your company",
    licensesNumber: "Number of licenses",
    maxLicensesNumber: "Maximum number of licenses:",
    originalPricePerLicense: "Original price per license",
    originalPrice: "Original price",
    originalPricePeriod: "Original price per period:",
    userMonth: "/user/month",
    pricePerLicenseWithDiscount: "Price per license with discount:",
    priceWithDiscount: "Price with discount:",
    totalPrice: "Total price:",
    installments: "Installments:",
    appliedDiscounts: "Applied discount:",
    purchaseDate: "Purchase date:",
    creationDate: "Plan creation date:",
    initBilling: "Billing start:",
    endTrial: "End of trial:",
    initTransaction: "Current billing start:",
    endTransaction: "Current billing end:",
    nextBilling: "Next billing:",
    renewalDate: "Contract renewal:",
    endDate: "End date:",
    autoRenewal: "Automatic renewal",
    paymentMethod: "Payment method:",
    seePlansDetail: "View plan details",
    successEditPlan: "Successfully edited this Plan",
    errorEditPlan: "Error on edit this Plan",
    TwoWeeksFree: "Free for 14 days",
    billingInfo: "Billing information",
    savingsOf: "Savings of",
    discountApplied: "Discount applied of",
    changePaymentMethod: "Change payment method",
    paymentChangedTo: "It will be changed to:",
  },

  imageUploader: {
    addImage: "Add Image",
    selectImageOrUrl: "Enter a URL or upload image",
    selectImage: "Select image",
    selectImageHint: "The image will be saved in your Google Drive",
    imageUrl: "Image URL",
  },

  textFieldRules: {
    requiredCNPJ: "CNPJ is required",
    insertingCNPJ: "inserting CNPJ...",
    requiredEmail: "Email is required",
    invalidEmail: "Invalid email",
    passwordsDoNotMatch: "Passwords do not match",
    mustContainCapitalLetters: "Must contain capital letters",
    mustContainLowercaselLetters: "Must contain lowercase letters",
    mustContainsNumbers: "Must contain numbers",
    mustContainSpecialCharacters: "Must contain special characters",
    requiredField: "Required field",
    minCharacters: "Minimum number of characters",
    maxCharacters: "Maximum number of characters",
    imageTooLarge: "Image too large",
    maxXCharacters: "Maximum {number} characters",
    max20characters: "Maximum 20 characters",
    max25characters: "Maximum 25 characters",
    max60characters: "Maximum 60 characters",
    max1490characters: "The value cannot be longer than 1490 characters",
    specialCharacters: "The field cannot contain special characters",
    noSpaceCharacters: "The field cannot contain only spaces",
    fieldLessThan: "The field must be smaller than",
    cannotContainOnlySpaces: "The field cannot contain only spaces",
  },

  navbar: {},

  sidebar: {
    home: "Home",
    techDiagnosis: "Tech diagnosis",
    patnerPanel: "Partner panel",
    clients: "Clients",
    billing: "Billing",
    plans: "Plans",
    services: "Services",
    notificationCenter: "Notification center",
    clientsBans: "Clients bans",
    toManage: "To manage",
    onboardingUser: "Create user",
    company: "Company",
    profile: "Profile",
    inventory: "Digital inventory",
    reports: "Reports",
    general: "General",
    accounts: "User accounts",
    permissions: "Permissions",
    emailSignatures: "Email signatures",
    vacationMessages: "Vacation messages",
    signAutomation: "Automatic application",
    control: "Control",
    conectaControl: "Conecta Control",
    hirePlan: "Hire a plan",
    offboardingUser: "Remove user",
    vacationMessage: "Vacation message",
    assignVacationMode: "Assign vacation mode",

    createUser: "Create user",
    removeUser: "Remove user",
    feedbackGroupCTA: "Join Now",
    upgradeCTA: "Upgrade",
    vacation: "Vacation",
    controlShifts: "Work shifts",

    basic: "Get access to more tools with the Security Plan!",
    standard: "Get access to more tools with the Security Plan!",
    security: "Get access to more tools with the Enterprise Plan!",
    enterprise:
      "Join our group of users providing feedback and ensure exclusive benefits!",
  },

  hirePlan: {
    economyOf: "Saving of ",
    paymentPlan: "Payment plan",
    paymentMinimumValue: "Minimum purchase value of R$40 not reached! ",
    decreaseLicenseRule:
      "The number of licenses cannot be less than the number of users in the company",
    paymentMinimumValueTextforFlex:
      "Select the annual payment plan to continue",
    paymentMinimumValueTextforAnnual:
      "Add extra licenses to continue in this mode.",
    goBackToPlans: "Return to plans",
    annualPayment: "Annual Payment",
    monthlyPayment: "Monthly Paymment",
  },

  billing: {
    initBilling: "Start of billing",
    discountOf: "Discount of",
    fromOriginalPrice: "from the original price",
    goToSuperLogica: "Go to SuperLógica",
  },

  selfCheckout: {
    minLicensesInfo: `To guarantee the quality of the services offered and the efficiency of the customer support, Conecta Suite establishes the obligation to contract a minimum of ${MIN_LICENSES} licenses.`,
    oneTimeCharge: "One-time charge",
    agileImplementation: "Agile Implementation",
    checkServiceDescription:
      "Check out what the Agile Implementation {name} offers",
    standardImplementationHint:
      "Implementation of two personalized email signatures reflecting your company's branding.",
    securityImplementationHint:
      "With the agile implementation, we will provide a tour of the Conecta Suite tools, explain important details for optimized use of Conecta Control, demonstrate how Conecta Control's restrictions work, and provide reports for analyzing platform activities. Additionally, the Conecta team will create two personalized email signatures reflecting your company's branding.",
    implementServiceContact: "Contact for Service Implementation",
    implementServiceContactDescription:
      "Our team will contact you to carry out the agile implementation of Conecta Suite. If you prefer, you can reach out via email at <strong>implementacao@conectasuite.com</strong>",
    implementService: "of implementation service",
    cart: "Cart",
    licensesTipForAnnual:
      "Set a number of additional licenses to contract the plan in the Annual mode. The smallest possible number corresponds to the number of users in your Google Workspace.",
    creditCardPaymentInstructions: "pay using credit card via Superlógica",
    bankSlip: "Bank slip",
    bankSlipPaymentTitle: "Hiring done!",
    receiveBankSlipEmail: "Receive the bank slip for payment via e-mail",
    orderDetails: "Order details",
    bankSlipDueDate: "Bank slip due date",
    paymentType: "Payment method ",
    creditCard: "Credit card",
    finishBuy: "Finish Purchase",
    clearCart: "Clear Cart",
    removePlanFromCart: "Remove plan from cart",
    confirmPlanPurchaseText: "Yes, hire this plan",
    implemantationHelp:
      "I would like the help of the Conecta Suite team to do the implementation",
    goToPayment: "Go to payment",
    confirmNewPlan: "Confirm new plan",
    overwriteCurrentPlan:
      "You already have one or more products from the plan you want to purchase. By proceeding, you agree that the current plan will be deactivated and the new one contracted.",
    newPlan: "New plan",
    overwriteCurrentPlanAlert:
      "Some products in your current plan are not included in the plan you want to purchase. Do you want to deactivate the actual plan and contract the new one?",
    trialEnded: "Your trial period has ended",
    trialEndedText:
      "Purchase a plan to continue enjoying all the features of Conecta Suite products",
    getBasicAfterTrialEnded: "After the end of the test (from",
    simulationAlert:
      "For security reasons, it is not allowed to contract plans during the login simulation. <br /> Instruct a Google administrator of this company to perform the operation on this screen, or perform it from the customer's page in the Conecta panel",
    notApplicable: "Not applicable",
    helpCardFeatureListTitle1:
      "Access SuperLogica and make the payment on the platform",
    helpCardFeatureListSubtitle1:
      "Your SuperLogica account has been created, use your email to access the website! The data filled in SuperLógica will be used to charge the plan.",
    helpCardFeatureListTitle2: `You will soon receive an email to make the payment by credit card!`,
    helpCardFeatureListSubtitle2: `If you have not been redirected, <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">click here</a>.`,
    helpCardFeatureListTitle3: `Don't worry! We will send a confirmation email as soon as we receive your payment!`,
    helpCardFeatureListSubtitle3: `For your security, we do not save your credit card data in our database or anywhere in the application.`,

    helpBankSlipFeatureListTitle1:
      "The hiring information has been sent to the financial email registered in the company profile.",
    helpBankSlipFeatureListSubtitle1:
      "The email contains important information about the contracted plan, payment method, price per license and the date the charge will begin.",
    helpBankSlipFeatureListTitle2: `You will soon receive an email containing the bank slip to make the payment!`,
    helpBankSlipFeatureListSubtitle2: `The bank slip will also be available on SuperLogica. You can access the customer area <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">by clicking here</a> and entering your email.`,
    helpBankSlipFeatureListTitle3:
      "You already have access to the tools! Feel free to use them.",
    helpBankSlipFeatureListSubtitle3: "",

    hirePlanSnackbarPrefix: "The plan ",
    hirePlanSnackbarSuffix:
      " was successfully hired. A confirmation email will be sent with the sender",
    hireBasicPlanRule:
      "This plan will be available for purchase after the end of the plan in Trial",
    choosePlanPaymentType: "Choose the payment plan:",
    trialPeriod: "Trial period",
    beingRedirectdToSL: "You will be redirected to SuperLogica in",
    helpWithPaymentText:
      "Do you have any questions regarding payment or plans?",
    accessPageSuportText: `Send us an email to <a href="mailto:financeiro@conectasuite.com" target="_blank">
      financeiro@conectasuite.com
    </a> or access our support page and get your questions answered.`,
    almostThere: "You're almost there!",
    plansDescriptions: {
      basic: {
        availability: "Available for Google Workspace (G Suite)",
        individualSignatures:
          "Create, edit and apply individual HTML email signatures for users",
        absenceManagement:
          "Manage vacation, holiday and absence messages for each user",
        groupManagement:
          "Add users to email groups, calendars and shared drives",
        externalEmail:
          "Allow your collaborators to receive and send messages from other email addresses",
        delegatedManagement:
          "Delegate management permissions to other users in the simplified Conecta Suite panel",
        adminReport:
          "Detailed report with improvement points for the Google Workspace Admin panel",
        userSetup:
          "Simplified registrations and configurations for new users (new collaborator)",
        accessSuspension: "Suspension of user access (former collaborator)",
      },
      standard: {
        availability: "Available for Google Workspace (G Suite)",
        basicBenefits: "Benefits of the Basic plan",
        bulkSignatures: "Manage email signatures in bulk via advanced editor",
        createSignatureTemplates:
          "Create an unlimited gallery of email signature templates for your company",
        scheduledUpdates: "Schedule email signature updates for special dates",
        absenceSignatures:
          "Create, edit and apply vacation, holiday and absence message signatures in bulk or individually via advanced editor",
        signatureTemplates: "Access ready-made email signature templates",
        connectedApps:
          "Report of applications connected to users' corporate email",
        delegationExpiration: "Set mailbox access delegation expiration",
      },
      security: {
        availability:
          "Available for Google Workspace (G Suite) and Microsoft 365",
        basicBenefits: "Benefits of the Basic plan",
        standardBenefits: "Benefits of the Standard plan",
        autoReapply: "Automatic reapplication of email signatures",
        accessControl:
          "Limit collaborators' access to the company's digital environment by time, operating system, internet browser, DNS, VPN and IP",
        customShifts: "Create access rules with unlimited custom shifts",
        deviceDisconnect:
          "Disconnect the collaborator from all devices at the configured time automatically or with just one click",
        licenseDeactivation: "License deactivation (former collaborator)",
        loginReport: "Report of corporate email logins and logouts",
        fileSharingReport: "Report of corporate file sharing",
        customLogin:
          "Custom login screen with company information and visual identity",
        migrationBackup:
          "Migrate emails and files from Drive between users and groups to back up",
      },
      enterprise: {
        availability:
          "Available for Google Workspace (G Suite) and Microsoft 365",
        basicBenefits: "Benefits of the Basic plan",
        standardBenefits: "Benefits of the Standard plan",
        securityBenefits: "Benefits of the Security plan",
        seniorIntegration: "Integration with Senior Systems",
        vrGenteIntegration: "Integration with VR Gente (Pontomais)",
        activeDirectory: "Integration with Active Directory (AD)",
        tangerinoIntegration: "Integration with Sólides Tangerino",
        otherIntegrations: "Integrations with other systems",
      },
    },
    serviceDescriptions: {
      standard: {
        adjustmentsAndGoodPractices: "Adjustments and best practices",
        adjustmentsUsers: "Workspace and Conecta Suite user adjustments",
        signatureConfig:
          "Configurations for applying necessary signatures and delegations",
        goodPracticesEmail:
          "Best practices for sending emails and signature configurations",
        spamOrientation: "Validations and guidelines to reduce SPAM issues",
        infoSecurity: "Information security best practices",
        workspaceTraining:
          "Guidelines and training for creating email groups in Google Workspace",
        signatures: "Email signatures",
        signatureModels: "Development of 2 custom signature models",
        applyInBatch: "Application of the 1st signature to all users",
      },
      security: {
        controlAccessConfig: "Access control configuration and activation",
        shifts: "Definition and configuration of shifts",
        controlActivation: "Conecta Control configuration and activation",
        enterpriseIntegrations: "Integrations - Conecta Suite Enterprise",
        adIntegration: "AD Integration configuration and activation",
        sistemIntegration:
          "Configuration and activation of integration with the timekeeping system",
      },
    },
    confirmingNewPlan: {
      youAreAboutToLose: "By changing the plan you are losing:",
      wantBenefits: "I want my benefits",
      dontWantBenefits: "I understand and I do not want my benefits",
      beforeContinuing: "Before continuing...",
      howDoYouRate: "How do you rate your actual plan?",
      tellUsTheReason: "Tell us the reason for downgrading your plan",
    },
    churnReasons: {
      tooExpensive: "Too expensive: The cost is too high.",
      notUsed: "Not used: Paying for features not used.",
      foundCheaper: "Competition: Found a cheaper alternative.",
      foundBetter: "Competition: Found a better alternative.",
      noLongerNeeded: "No longer needed: Services are no longer necessary.",
      difficultToUse: "Poor experience: Difficult to use or manage.",
      billingProblems: "Billing: Problems with billing or payments.",
      notConfident: "Security: Not confident in security features.",
      lackOfIntegrations: "Integrations: Need additional integrations.",
      otherReason: "Other reason:",
    },
    annualDowngradeBlock:
      "It is not possible to downgrade the plan in the annual mode. Please contact us via email at experience@conectasuite.com.",
    subscriptionTypeChangeBlock:
      "Changing the plan's periodicity is not allowed.",
  },

  purchaseLoading: {
    savingPlanInfo: "Saving information about your new plan...",
    youBeeingRedirected: "You will be redirected to complete the payment...",
    redirectingIn: "You will be redirected in ",
  },

  companyInformation: {
    missingCNPJAlert:
      "This company <b>does not have a registered CNPJ</b>. To proceed with the purchase, it is necessary to fill in the mandatory information.",
    billingInfo: "Billing Information",
  },

  advicePermissionDialog: {
    companyInfoTitle: "No access permission",
    companyInfoMessage:
      "You do not have permission to access the Company Profile. Ask an administrator to complete the registration of this company before closing a plan.",
  },

  companyProfileForm: {
    saveAndReaply:
      "Save the changes and reapply subscriptions with the updated information",
    saveWithoutReapply:
      "Save the changes to the information without reapplying the subscriptions",
  },

  comparativePlan: {},

  periodicity: {
    monthly: "Monthly",
    annual: "Annual",
    ANNUAL: "annual",
    FLEX: "monthly",
    month: "month",
    year: "year",
    annualPayment: "Annual (Annual payment)",
  },

  errorMessages: {
    registerPlan: "Oops! Error registering plan information",
    totalCalc: "Error calculating total value",
    updateCompanyData: "Error updating company information",
    fillingRequiredFields: "Please fill in the required fields",
    USER_NOT_FOUND: "User not found",
    USER_GOOGLE_ADMIN: "The user is a Google administrator",
    USER_ALREADY_SUSPENDED: "The user is already suspended",
    USER_ALREADY_ACTIVE: "The user is already active",
    setSignatureToUsers: "Unable to apply signature changes, please try again",
    FREE_USAGE_LIMIT_EXCEEDED:
      "The free usage limit has been reached and it is necessary to purchase a plan to continue using the functionality.",
    requestSent: "Error when sending request",
    updateTags: "Error updating the information",
  },

  successMessages: {
    dataUpdated: "Data updated successfully",
    setSignatureToUsers:
      "The email signatures of selected users will soon be changed.",
    scheduleSignature:
      "The appointment was made for: {date}. Track appointments in 'Automatic application'.",
    requestSent: "Request sent successfully!",
    templateApplied: "Operation completed successfully!",
  },

  techDiagnosis: {
    title: "Tech Diagnosis",

    sessions: {
      generalInfo: "General Information",
      economyInfo: "Economy",
      emails: "Emails",
      security: "Security",
    },

    fillResearchValues: "Fill in the Google Workspace data to check values.",
    goToGoogleWorkspace: "Access Google Workspace",
    chatSupport: "Chat Support",
    analyzingCompany: "We are analyzing your company.",
    companyInOrder: "Your company is in order.",
    companyHasPointsOfImprovement:
      "We have identified areas for improvement for your company.",
    companyHasPointsOfAttention:
      "We have identified areas of concern for your company.",
    getAnAnalysis: "Receive an analysis of your company.",
    kindUsers: "users",
    kindUser: "user",
    totalPrice: "Total: R$ {price} per month",
    totalPriceTooltip:
      "We have identified that your company may have an unnecessary total cost of up to R${price} per month.",

    workspaceResearch: {
      almostThere: "Almost there...",
      answerQuestionsBellow:
        "For a better diagnosis result, please answer the questions below:",
      partnerMethodLabel: "Your Google Workspace hiring method is:",
      renewalDateLabel: "What is the renewal date of the current contract?",
      priceLicenseLabel: "What is the price per license? (R$ user/month)",
      directWithGoogle: "Directly with Google",
      authorizedPartner: "Via Authorized Partner",
      ruleMaxValue: "The value cannot be greater than 1000.",
      ruleMinValue: "Please provide a valid value.",
    },

    workspaceInfo: {
      title: "Google Workspace",
      missingData:
        "Data not provided: Fill in the Google Workspace data to receive an appropriate diagnosis.",
      editData: "Edit Data",
      fillData: "Fill Data",
      goToGoogleAdmin: "Go to Google Workspace Admin Console",
      partnerMethod: "Hiring Method: ",
      renewalDate: "Renewal Date: ",
      activeLicenses: "Active Licenses: ",
      pricePerLicense: "Price per License: ",
      priceResult: "R${price} user/month",
    },

    usersGraphics: {
      title: "User Graphs",
      inactive: "Inactive",
      suspended: "Suspended",
      active: "Active",
      individual: "Individual",
      couldBeGroups: "Could be Groups",
    },

    generalRow: {
      totalPrice: "R$ {price} per month",
      totalPriceText:
        "We have identified that your company may be incurring an unnecessary cost of up to R${price} per month in this category.",
      withoutResearchPriceText:
        "Based on the values of the Google Workspace Business Plus Plan, your company may have an unnecessary cost of up to R${price} per month in this category. Please fill in the license values ​​on the side to receive an appropriate diagnosis.",
      neverLogged: "Never Logged",
      usersKind: "users",
    },

    inactiveUsers: {
      title: "Inactive Users",
      tooltipText:
        "There are inactive users in your Workspace. Inactive users may be generating unnecessary costs for your company. {researchAlert}",
      inactiveDays: "Inactive for more than {numberOfDays} days",
    },

    suspendedUsers: {
      title: "Suspended Users",
      tooltipText:
        "There are suspended users in your Workspace. Suspended users may be generating unnecessary costs for your company. {researchAlert}",
    },

    couldBeGroupsUsers: {
      title: "Users Who Could Be Groups",
      tooltipText:
        "We have identified users who could be groups to optimize your costs. By using groups, you can enjoy free email and other information organization benefits, as well as follow Google best practices. For more information, consult our support.",
    },

    inactiveAppsUsers: {
      title: "Inactive Users Applications",
      tooltipText:
        "We have identified inactive users maintaining connections in external applications. This may represent additional costs or security risks for your company. Access your inventory to learn more.",
      app: "application",
      apps: "applications",
      accessInventory: "Access your inventory",
    },

    withoutSignatureUsers: {
      title: "Users Without Email Signature",
      tooltipText:
        "There are employees without signatures! Now use professional email signatures from Conecta Suite to keep your data and brand identity up to date.",
      goToSignature: "Go to signatures",
    },

    DNS: {
      title: "Domain Quality",
      tooltipText:
        "Some domains need your attention! Check the MX, SPF, and DKIM of your domains. If you have any questions, contact us through our chat support.",
      domains: "domains",
      domain: "Domain",
      knowMore: "To understand more about MX, SPF, and DKIM of domains: ",
      FAQ: "see our FAQ",
      chatSupport:
        "If you have any questions, contact us through our chat support.",
      helpArticle: "See help article",
      selector: "Selector",
      editSelector: "Edit Selector",
      selectorInfo:
        "The DKIM selector is a unique identifier for a DKIM signature domain.",
      spamVerify: "Check if the domain appears on any Spam blacklist",
      useThe: "Use the",
      seeOur: "See our",
      knowMoreBlackLists:
        "to learn more about Spam blacklists and how to keep your domain safe on Google Workspace.",
      knowMoreDomains:
        "To learn more about your domain information, consult the",
      ignoredDomainText:
        "Quality verification was automatically ignored for this domain because it does not send emails.",
      ignoredVerify: "Verification ignored",
      googleMxRecordsNotFound: "The domain must have at least one mail server.",
      priorityMxRecordFound:
        "Domain configured with the mail server of highest priority",
      allMxRecordsConfigured: "All MX records are configured",
      missingGoogleMxRecords: "Some MX records were not found",
      googleStandardSpf: "SPF follows Google's standard",
      googleSpfNonStandard:
        "The configured SPF does not follow the standard adopted by Google",
      missingSpfDnsTxt: "SPF not configured!",
      foundDkim: "DKIM configured",
      missingDkim: "DKIM not configured",
      missingDomainData:
        "Could not find a nameserver IP that displays this domain.",

      alertMessages: {
        BAD: "Some critical problems were detected in the configuration of this domain.",
        WARNING:
          "Some non-critical problems were detected in the configuration of this domain.",
        OK: "Domain settings, MX, SPF, and DKIM were successfully configured",
      },
    },

    without2faUsers: {
      title: "Users Without Two-Factor Authentication",
      tooltipText:
        "The security of your employees' accounts can be enhanced by enabling two-factor authentication (2FA). This measure is essential to strengthen the protection of your company's data, adding an additional layer of security. We recommend accessing security settings in the workspace and enabling 2FA, thus ensuring the integrity and confidentiality of your data and business information.",
    },

    googleDriveReports: {
      title: "Google Drive Files and Folders",
      tooltipText:
        "Files and folders in your corporate Drive may be shared externally. We recommend that you check the corresponding report and ensure that you have full control of your data",
      files: "Files",
    },

    googleTakeout: {
      title: "Ensure Corporate Data Protection",
      tooltipText:
        "Your users may have access to Google Takeout and export sensitive data from your company. Check your security!",
      knowHowToVerify: "Know how to verify here",
    },
    privacyCheckup: {
      title: "Ensure your privacy",
      tooltipText:
        "Review your main privacy settings based on the Google services you use the most.",
      knowHowToVerify: "Review by clicking here",
    },
  },

  onboardingNewUser: {
    welcomeOnboardingTitle: "Welcome to create user",
    welcomeOnboardingSubtitle: "Before starting you should know",
    totalStepsLabel: "This is a 7 step process.",
    progressAutomaticSaveLabel:
      "You can pause this process at any time and your progress will be saved.",
    requiredFirstStepLabel:
      "The first step is mandatory so that the others can be executed.",
    suportHelperLabel:
      "If you have any questions, you can contact our support using the service channels.",
    recommendedDeviceLabel:
      "To optimize your productivity and performance, we recommend considering using a desktop for this flow.",
    userProfilePhoto: "User profile photo",
    uploadImage: "Upload image",
    loginAndPasswordManagement: "Login and password management",
    loginAndPasswordManagementDescription: "Password assigned to access Gmail",
    loginAndPasswordManagementDescriptionWithControl:
      "Password assigned to access Gmail by Google and Conecta Control",
    createPassword: "Create a password",
    generateAStrongPassword: "Automatically generate a strong password",
    password: "Password",
    confirmPassword: "Confirm password",
    copyOnNextStep:
      "You will be able to see and copy the password in the next step",
    requestPasswordChange: "Request password change on next login",
    createAndAdvance: "Create and advance",
    shifts: "Shifts",
    showingAllShifts: "Showing all shifts",
    searchShift: "Search shift",
    selectedShifts: "selected shifts",
    participants: "Participants",
    login: "Login",
    loginTooltipText:
      "Validates whether the user is on working hours via time tracking software.",
    logout: "Logout",
    logoutTooltipText:
      'Shift participants are logged out at the end time of an "access rule" (period) of the shift.',
    passwordPolicy: "Password Policy",
    passwordPolicyTooltipText:
      "Defines the time interval for users to change their personal passwords.",
    allShiftsSelected: "All shifts in the table have been selected.",
    companyResources: "Company Resources",
    companyCalendars: "Company Calendars",
    finishOnboardingTitle:
      "Congratulations! The new user is now ready to log in for the first time.",
    finishOnboardingSubtitle: "Now you can",
    guidanceForSendingAccessToUser:
      "Send the login data (email and password) so that the user can access it for the first time.",
    rateExperience: "Rate your experience with our create user flow.",
    all: "All",
    shiftsSelectedsInPage: "shifts on this page are selected.",
    shiftsSelectedsInTable: "table shifts are selected.",
    selectAllShifts: "Select all shifts",
    clearSelection: "Clear selection",
    resetSelection: "Reset selection",
    changeEvery: "Change every",
    days: "days",
    nextPasswordChange: "Next password change",
    pointControl: "Point Control",
    verifyPlanDrive:
      "Verify that the Google Workspace Drives plan is active for your company.",
    companySharedDrivesNotFound: "The company's shared drives were not found",
    instructions: {
      userCreated: "The user is already created in your Workspace Panel!",
      reminderInfos:
        "Remember to inform the user, the email and password configured for login!",
      recommendations:
        "We recommend performing <strong>user synchronization</strong> in Conecta Suite, to keep the user list correctly updated.",
      copyLoginInstructions: "Copy login information",
      sendLoginInstructions: "Send login instructions",
      sendInfoDescription:
        "An email will be sent with the login credentials directly to the user's email address.",
      templateEmail: {
        subjectEmail: "You have a Google Workspace account for {company_name}",
        content:
          '<div style="font-family: inherit; text-align: start"><span>Hello,</span></div><div text-align: start"><br></div><div><span>You have a new Google Workspace account in the organization {company_name}.<br>Your credentials for the first access are:</span></div><div><span><strong>Email</strong></span><span>: {user_email}</span></div><div><span><strong>Password</strong></span><span>: {user_password}</span></div><div><br></div><div><span>Use </span><a href="https://mail.google.com/"><span><u>Gmail</u></span></a><span> to login </span></div><div><br></div><div><span>After accessing, it is important to reset your password and ensure the security of your account.<br>Best regards,<br>Conecta Suite Team</span></div>',
      },
    },
    controlShift: {
      infoAboutThisStep:
        "Assign the user to work shifts configured in Conecta Control.",
      createShift:
        'If necessary, create a new shift and use the "Reload Shifts" button to update the table below.',
      noShifts:
        'You do not have any shifts yet, click on "Create Shifts" and set the start and end times for each shift.',
      hasNotAccessedControlYet: "You have not accessed Conecta Control yet.",
      hasNotAccessedControlYetDescription:
        "Access now and enjoy the benefits of user access control.",
    },
    fillRequiredFields:
      "Fill in the required fields and click 'Create and continue'",
    selectOrCreateUser: "Select or create a user to proceed to the next step",
  },

  userInformations: {
    workPhone: "Work phone",
    office: "Office",
    signaturePhotoUrl: "Signature photo URL",
    signaturePhotoUrlTooltipText:
      "To avoid issues with your users' images, it is recommended that the image of <b>each user is a URL of an image shared with everyone on Google Drive</b>. You can also use <b>images hosted by your own server</b>.",
    userInfosDescription:
      "The relevant information about the user will be saved in the user's profile in Google Workspace.",
    urlForScheduling: "URL for scheduling",
    customTag1: "Custom tag 1",
    customTag2: "Custom tag 2",
    customTagTooltipText:
      "Add custom information to use in your signature templates",
    customTagPlaceholder1: "Custom tag content 1",
    customTagPlaceholder2: "Custom tag content 2",
    mobilePhone: "Mobile phone",
    departament: "Department",
    shedulingHelpText:
      "Learn how to create a scheduling link in Google Calendar <a target='_blank' style='text-decoration: none' href='https://youtu.be/lmJ5clI-bwo'><b>clicking here</b></a>.",
    title: "Edit user information",
    alertTagInfoText:
      'The information is responsible for personalizing the signature user email. Find out more at <a target="_blank" class="accent--text" href="https://conectasuite.com/perguntas-frequentes/conecta-sign/como-usar-tags-na-assinatura-de-e-mail/" >how to use Information in your email signature.</a>',
    personalInformation: "Personal information",
    employeelInformation: "Employee information",
    customInformations: "Custom informations",
    nameAndLastName: "First name + last name",
    editOnlyInWorkspace:
      "This field can only be changed in the user's Google Workspace account.",
    addNewTag: "Add new tag",
    newCustomTag: "New custom tag",
    representation: "Representation",
    whatsapp: "WhatsApp",
    extension: "Extension",
    userPhoto: "User photo",
    signPhotoAlertText:
      "To avoid problems with your users' images, it is recommended that <b>each user's image is a URL of an image shared with everyone on Google Drive</b>. You can also use <b>images hosted on your own server</b>.",
    upload: "Upload",
    addImage: "Add image",
    selectImage: "Select image",
    imageSavedOnDrive: "The image will be saved to your drive",
    deleteCustomTag: "Delete custom tag",
    deleteCustomTagDescription:
      "Do you want to confirm the deletion of the tag",
    confirmInformationChanges: "Confirm information changes?",
    saveInWorkspaceInfoText:
      "Select the checkbox to save user data to Google Workspace. Remember, only the data below can be updated in Google Workspace",
    saveInWorkspaceCheckboxText:
      "Would you like to update user data in the workspace?",
    reapplyConfirmationText:
      "Do you want to reapply the signature model with the new information for the user",
    reapplyEmailSignature: "Reapply email signature",
    request: "Request to update information",
    emailSending:
      "We will send an email with a unique link for each selected user to update their information.",
    process: "The update process is quite simple:",
    emailReceipt:
      "Email receipt: Each user receives an email with a personalized link.",
    linkAccess:
      "Link access: By clicking on the link, the user is directed to update their data.",
    deadline:
      "The deadline for making changes is 7 days from the receipt of the email.",
    organizationUnitDescription:
      "User's organizational unit configured in Google Workspace",
    departamentDescription: "User department configured in Google Workspace",
    officeDescription: "User role configured in Google Workspace",
    function: "Function",
    functionDescription:
      "Whether the user is an administrator or just a member of the Workspace",
    usersShouldApply: "User should also apply the e-mail signature",
    endDate: "End date",
    emptyState: {
      signature: {
        noData: "We couldn't find any email signature records",
        cta: "Apply your first signature by clicking the button below!",
        action: "Apply signature",
      },
      permissions: {
        noData: "We couldn't find any configured permissions",
        cta: "Set access permissions by clicking the button below!",
        action: "Manage permissions",
      },
      vacation: {
        noData: "We couldn't find any configured vacation messages",
        cta: "Apply a vacation message by clicking the button below!",
        action: "Apply message",
      },
      delegates: {
        noData: "We couldn't find any delegated mailbox records",
        cta: "Delegate mailbox access by clicking the button below!",
        action: "Delegate access",
      },
      vacationControl: {
        noData: "We couldn't find any users with active vacations",
        cta: "Set vacations for one or more users by clicking the button below!",
        action: "Configure vacation",
      },
      shifts: {
        noData: "You haven't set up any work shifts yet",
        cta: "Set up work shifts by clicking the button below!",
        action: "Configure shifts",
      },
    },
  },

  feedbackRating: {
    rating_error: "Select a satisfaction level to send the survey.",
    noRating: "No rating",
    leaveAComment: "Leave a comment",
    veryDissatisfied: "Very dissatisfied",
    dissatisfied: "Dissatisfied",
    indifferent: "Indifferent",
    satisfied: "Satisfied",
    verySatisfied: "Very satisfied",
    sendSugestion: "Send sugestion",
    didNotWork: "It didn't work",
    confusingInterface: "Confusing interface",
    slowPerformance: "Slow performance",
    incorrectInformation: "Incorrect information",
    incorrectSignature: "Incorrect signature",
    tooComplexModel: "Too complex model",
    moreOptionsNeeded: "More options needed",
    incorrectChanges: "Incorrect changes",
    incorrectDelegation: "Incorrect delegation",
    emailNotFound: "Email not found",
    incorrectEmail: "Incorrect email",
    why: "Why",
    evaluationRegistered: "All ready, evaluation registered!",
    rateOnMarketplace: "Rate the app on the marketplace",
    newAssessment: "New assessment",
  },

  login: {
    slogan:
      '<span class="login-text-spotlight">Simplified</span> management of <span class="login-text-spotlight">corporate users</span>',
    title: "Connecting management with technology",
    accessDelegation: "Access delegation",
    emailSignature: "Email signatures",
    accessControl: "Access control",
    muchMore: "and much more!",
    createAccountTerms: "By creating an account, you agree to our",
    termsOfUse: "Terms of use",
    privacyPolicy: "Privacy policy",
    businessManagementSystem: "Business Management System",
    riskReduction: "Risk Reduction",
    agileUserManagement: "Agile User Management",
    moreSecurity: "More Security",
  },

  dashboard: {
    conectaSign: {
      description:
        "Manage email signatures quickly and easily. Create custom templates or use our ready-made templates and bulk edit for all users, maintaining your company's identity.",
      shortDescription:
        "Create and edit email signatures and vacation messages for all Google Workspace users",
    },
    conectaTrack: {
      description:
        "Automatically know where working hours are allocated. Generate reports by periods and know who is online in real time.",
      shortDescription:
        "Monitor and automatically record activities in Google Chrome",
    },
    conectaControl: {
      description:
        "Define who will have permission to access Google Workspace (G Suite) tools, from anywhere at any time. And who will only be able to access from the company during working hours.",
      shortDescription:
        "Control access through the times and locations of use of Google Workspace",
    },
    conectaSuite: {
      description: "This option is part of the company's administrative panel!",
      shortDescription: "",
    },
    workspaceDiagnosis: {
      description:
        "Identify potential problems and improvements for your company",
      shortDescription: "",
    },
    manageAdmins: {
      description:
        "Manage administrators and give exclusive access to managers",
      shortDescription: "",
    },
    reseller: {
      description:
        "Manage your customers and review billing through the administrative panel",
      shortDescription: "Manage your customers through the partner panel",
    },
    companyProfile: {
      description: "Manage your company's profile data!",
      shortDescription: "Update and view company information",
    },
    billing: {
      description: "Access your subscriptions, services and billing history!",
      shortDescription:
        "Manage the company's contracted plans and billing history",
    },
    users: {
      description:
        "Configure: vacation message, access delegation, send email as and email signature!",
      shortDescription: "",
    },
    inventory: {
      description: "Manage your company's digital inventory!",
      shortDescription: "",
    },
    reports: {
      description: "Track user activity reports!",
      shortDescription: "",
    },
    contactUs: {
      description: "Can we help?",
      shortDescription: "",
    },
    manageEmailSignatures: "Manage email signatures",
  },

  upgradeBanner: {
    trialTitleExpired: "Oops, your trial period has ended!",
    trialSubtitleExpired:
      "Purchase a plan to continue enjoying all the features of the Conecta Suite products!",
    trialTitlePeriod: "Are you enjoying the Conecta Suite?",
    trialSubitlePeriod:
      "Secure your plan now without losing your remaining days of free trial. Plans starting at $2.50/user/month!",
    subtitleUpgradeTextDense:
      "Unlock more features. Explore, create or customize signature templates and apply them in bulk.",
    standardPlanAndUp: "Available from the Standard plan.",
    subtitleUpgradeTextNotDense:
      "Unlock more features, control access by time, location, and browser to Workspace with Conecta Control",
    subtitleUpgradeTextHasBasic:
      "More features: apply mass signatures and vacation messages",
    subtitleUpgradeTextNotBasic:
      "More features: control access by time, location, and browser to Workspace",
    upgradePlan: "Upgrade available for you",
  },

  functionalities: {
    licenseManager: "Manage Licenses",
  },

  scopesAlert: {
    alertTitle: "Attention!",
    emailMigration: {
      alertMessage:
        "We identified pending scopes in the Conecta Suite application",
      resolveScopes:
        "The proper functioning of the email migration depends on the authorization of these scopes.",
    },
    driveMigration: {
      resolveScopes:
        "The proper functioning of the file transfer depends on the authorization of these scopes.",
    },
    alertMessage1:
      "Important features may not work properly due to pending scopes in the application",
    alertMessage2:
      "If you have any questions, please contact our support team through the service channels.",
    alertMessage3:
      "Please contact your Google Workspace administrator to request authorization for the necessary scopes.",
    alertMessage4:
      "The <strong>URGENT</strong> resolution of these scopes is essential for the FULL OPERATIONALITY of the system.",
    requestAdminToAcceptScopes:
      "Please ask your administrator to accept the scopes to use this feature.",
    requestToAcceptScopes:
      "Please accept the scopes in your Google Workspace to use this feature.",
    alert:
      "{functionality} feature is not available due to missing scopes in the application.",
  },

  scheduleMeeting: {
    message:
      "As you are new here, how about scheduling a conversation with our team of experts to clarify all your doubts?",
    talkToOurSpecialists: "Talk to our specialists",
    knowOurPlans: "Know our plans",
  },

  driveMigration: {
    title: "File transference",
    steps: {
      intro: {
        title: "Introduction",
        subtitle: "Advantages of Conecta Suite file transference",
      },
      processes: {
        title: "Processes",
        subtitle:
          "Resume ongoing processes, start new ones, or view completed ones",
      },
      start: {
        title: "Start",
        subtitle: "Select a user's drive and a group for the shared drive.",
      },
      prepare: {
        title: "Prepare",
        subtitle: "Wait while we prepare the data to start the process.",
      },
      executing: {
        title: "Execution",
        subtitle: "Transfer files with other collaborators or alone.",
      },
    },
    intro: {
      title: "Transfer your employees' files",
      description:
        "Using your company's shared Drive, you can reduce your employees' storage usage without removing access to files. Here you will also perform this transference following Google Workspace best practices.",
      benefits: {
        1: "Reduces employee storage usage",
        2: "Makes the most of shared storage",
        3: "Manages access to files through Workspace groups",
      },
    },
    processes: {
      title: "Processes",
      resume:
        'If you want to run a process that is not yet completed, click "Resume".',
      start: 'Click "Start process" to start a new one.',
      callSupport: "Contact our support to identify a possible failure.",
    },
    start: {
      title: "Start process",
      userTip: "This is the user whose files will be transfered.",
      groupTip:
        'Select a group for the user and click "Prepare" to transfer files.',
      selectGroup: "Select group",
      tryUser: "Try selecting a user first",
      startTransfer: "Start drive transfer",
      userAccessDrive: "Users that will have access to the shared drive",
      hasExternalMember: "Warning! Some members of this group may be external!",
      tooltipLabel:
        "These users will receive an email informing them about the access to this shared drive, and they will be able to view and access the files in it.",
    },
    prepare: {
      loading: "Preparing batches and creating folders...",
      waitFinish: "Please wait. This process may take a few minutes.",
      ready: "All set!",
      filesAndFolders: "Number of files and folders",
      files: "Number of files",
      batches: "Number of batches",
      continue: 'Click "Advance" to start the transference!',
      folders: "Number of folders",
      create: "Folders created",
    },
    executing: {
      title:
        "Transfering files from <strong>{email}</strong> to shared drive <strong>{group}</strong>",
    },
    tips: {
      batches: {
        total: "Total files",
        finished: "Completed files",
        missing: "Missing files",
        failures: "Files with failures",
      },
      workers: {
        ran: "Users that were running",
        running: "Users running",
        missing: "Missing users",
      },
    },
    errors: {
      title: "The file transference has failed",
      createSharedDrive:
        "You don't own shared drives. Make sure you have a proper Google Workspace license.",
      prepareSharedDrive:
        "Could not prepare the shared drive. Make sure you have accepted the scopes.",
      listUserFiles:
        "No files were found for the selected user. Make sure the user owns the files in their drive.",
      buildFolders:
        "An error occurred. Try again later, or contact our support.",
    },
    startProcess: "Start process",
    unavailable: "Only available for Security plan or higher.",
    tempUnavailable: "Temporary unavailable. In maintenance.",
    upsellInvitation:
      "Drive File Migration is not available for your plan. Discover the available plans and upgrade to back up your files with data transfer.",
  },

  priceChangeWarning: {
    warning:
      "From <strong>July 1, 2024</strong>, prices for Conecta Suite's Standard, Security and Enterprise plans will be adjusted according to the table available on the website",
    noActionNeeded: "No action is required.",
    willingToHelp: "We are available to answer your questions via email",
    thanks4BeeingClient: "Thank you for being a Conecta Suite customer.",
    iUnderstood: "I understood",
    subtitle: "Notice about plan price changes",
    priceAdjustment: "Adjustment in the price of Plans",
  },

  emailMigration: {
    upsellInvitation:
      "Email Migration is not available for your plan. Discover the available plans and upgrade to back up your emails with data transfer.",
    title: "Email Migration",
    subtitle:
      "Backup the user's emails to a Google Workspace group. Only one migration can be performed at a time at the enterprise level.",
    steps: {
      intro: {
        title: "Introduction",
        subtitle: "Benefits of email migration from Conecta Suite",
      },
      processes: {
        title: "Processes",
        subtitle:
          "Resume ongoing processes, start new ones or view completed ones",
      },
      start: {
        title: "Start",
        subtitle:
          "Select the user's email and the destination group for the migration.",
      },
      prepare: {
        title: "Prepare",
        subtitle: "Please wait while we prepare the data to start the process.",
      },
      executing: {
        title: "Execution",
        subtitle: "Perform the migration alone or with other collaborators.",
      },
    },
    intro: {
      title: "Migrate your collaborators' emails",
      description:
        "Migrate your collaborators' emails to Workspace groups securely, being able to start or pause the processes at any time.",
      benefits: {
        1: "Reduces the loss of information due to the deletion of important emails",
        2: "Takes advantage of the use of group resources",
        3: "Manages access to emails through Workspace groups",
      },
    },
    start: {
      title: "Start process",
      userTip: "Select the user whose emails will be migrated:",
      groupTip: "Select the destination group:",
      selectGroup: "Select group",
      tryUser: "Select a user first",
      startDateTip: "Select the emails start date:",
      selectStartDate: "Selecionar start date",
      theEntirePeriod: "The entire period",
      pastYear: "Past year",
      past1Month: "Past month",
      customPeriod: "Custom period",
      includeSpamAndTrashTip:
        "Would you like to include spam and trash emails?",
      includeSpamAndTrash: "Include spam and trash emails",
    },
    prepare: {
      loading: "Preparing batches...",
      ready: "All set!",
      files: "Number of emails",
      batches: "Number of batches",
      continue: "Click on next to start the migration!",
    },
    executing: {
      title: "Migrating emails from {email} to group {group}",
    },
    tempUnavailable: "Temporary unavailable. In maintenance.",
    includeTrashTip: "Do you want to migrate the emails that are in the trash?",
    includeTrashLabel: "Include trash emails",
    initMigration: "Start migration",
    selectGroupTip:
      "Select the group to which you want to migrate emails from ",
    migrateEmailsFromDate: "Choose the period for migration",
    dialog: {
      subtitle: "Group email migration",
      alert: "Before starting the email migration process, understand:",
      timeToFinishInstruction:
        "The email migration may take from a few minutes to several hours to complete.",
      closePageInstruction:
        "You can close this page as the process will occur in the background.",
      maxMailSizeTip: "Only emails up to 25MB in size will be migrated.",
      transferBackground:
        "The transfer will be done in the background, so you can continue with the remove user process without interruptions.",
    },

    overlay: {
      migrationRunning:
        "There is already a migration task in progress, and it is not possible to start simultaneous migrations at the enterprise level. Please wait for the current migration to complete and try again.",
      missingScopesAlert:
        "The email migration functionality is not available due to missing scopes in the application.",
    },
    startMigrationDateDescription:
      "The migration takes place from today until the chosen date",
    trashEmailTooltip: "The email migration period also applies to the trash.",

    emailsMigrated: "Migrated Emails",
    successfullyMigrated: "Successfully Migrated",
    migrationFailure: "Failed to Migrate",
    groupEmailTransfer: "Transfer of Emails to Groups",
  },

  offboardingUser: {
    welcomeOffboardingTitle: "Welcome to the remove user process",
    welcomeOffboardingSubtitle: "Before starting, you should know",
    totalStepsLabel: "This is a 7-step process",
    progressAutomaticSaveLabel:
      "You can pause this process at any time, and your progress will be saved.",
    suportHelperLabel:
      "If you have any questions, you can contact our support using the available channels.",
    selectTheUser: "Select the user",
    suspendTheUser: "Suspend the user",
    suspendTheUserCheckboxLabel:
      "The chosen user will be blocked from accessing the account and will be logged out. Their data will be blocked, but they won't receive emails, calendar invites, or files while they're suspended. Suspended users can be restored as long as they are not deleted.",
    changePassword: "Change Password",
    changePasswordDescription:
      "Reset the user's password to prevent them from accessing the account if the suspension is removed in the future.",
    removeFromControlShifts: "Remove from control shifts",
    passwordRuleMessage: "Password must be greater than 8 and less than 100",
    offboardingCompleted: "Remove user process completed",
    offboardingFailure: "An error occurred while completing the process",
    companyNoHasConectaControl:
      "Your current plan does not have Conecta Control",
    actionsExecuted: "Actions executed",
    aplicationsOfUser: "Applications connected to user {user_email}",
    appsUserSubtitle:
      "Select the applications you want to disconnect the user from:",
    notFoundConnection: "No connections found with this application.",
    appsNotDisconnected: "Some connections could not be deleted: {data}",
    moreThan: "More than 180 days.",
    finishOfboardingTitle:
      "Congratulations! The remove user process is complete.",
    removeLicenseAfterMigration:
      "The license for the user {email} will be removed after the data transfer.",
    notifyViaEmail: "You will be notified when the migrations are complete.",
    removeUserFromSuite:
      "The user will be removed from the suite after synchronization, but you can access them directly in Google Workspace.",
    transferEmailTitle: "Email Backup with Data Transfer",
    transferEmailSubtitle:
      "Migrating a user's emails to a Google Workspace group ensures that important information remains accessible to the team, promoting collaboration and transparency in the redistribution of responsibilities.",
    transferDriveTitle: "Transfer Drive Files",
    transferDriveSubtitle:
      "Transfer the files from user's drive to a shared drive to avoid losing files and information linked to it.",
    selectGroupToTransfer:
      "To start the transfer, choose a group the user is part of.",
    transferArquives: "Transfer Files",
    licenseAssignTitle: "License Management",
    licenseAssignSubtitle:
      "Removing licenses from former employees prevents unnecessary expenses on unused licenses, optimizing the company's budget.",
    licenseAssignAlert:
      "Licenses will only be removed after the completion of Drive, Email, and calendar data migration.",
    noLicensesAssigned: "No license assigned",
    in_queue: "In queue",
    exectutionOrder: "Execution order",
    hasTaskRunningAlert:
      "There is already an email migration in progress, the email migration of {email} will be added to a queue and will be executed after the current migration is finished",
    migrationsInQTitle: "Email migration for group in queue",
    migrationsInQSubtitle:
      "When the current migration is finished, the system will start the next migration",
    conecta_control_shifts: "Conecta Control Shifts",
    remove_user_shifts:
      "Remove the user from all Conecta Control shifts to block their access and maintain the organization of shifts.",
    update_password:
      "The user's new password will be changed in Conecta Control and Google Workspace.",
    suspend_user: "Suspend user in Google Workspace",
    hasTransferenceRunningAlert:
      "There is already a Drive file transfer in progress. Please wait for the current transfer to finish before starting the next one.",
  },

  dialogHasProduct: {
    yourCompany: "Your company still does not have {product}",
    yourCompanyPlan: "This feature is available from {plan} Plan onwards",
    knowBenefits:
      'Know all the benefits of {product} by clicking in "Know more"',
    knowPlanBenefits:
      'Know all the benefits of {product} Plan by clicking in "Know more"',
    trialExpired: "TRIAL period expired",
    askAdmin: "Ask the administrator to grant your access!",
    notAdminOf: "You are not administrator of ",
  },
  applySignature: {
    viewAppointments: "View appointments",
    goToUsers: "Go to users",
    viewEmailSignatures: "View email signatures",

    signatureChangeRequestSent: "Signature change request sent via email!",
    signatureChangeScheduledSuccess:
      "Email signature change scheduled successfully!",
    signatureAppliedSuccess: "Yay! Email signature applied 🎉",

    exclusiveLinkSignatureChange:
      "Email signature change via an exclusive link",
    exclusiveLinkEmailDescription:
      "Each selected user will receive an email with an exclusive access link to change their email signature",
    fillOutInformation: "Fill out information",
    exclusiveLinkInformationDescription:
      "The exclusive link also allows selected users to fill out their respective registration information",

    signatureChangeScheduled: "Email signature change scheduled!",
    signatureScheduledDescription:
      "Conecta Suite will change the email signature on the scheduled date.",
    signatureAppliedGmail: "The email signature has been applied in Gmail!",
    signatureDelayDescription:
      "The signature may take up to 15 minutes to appear.",
    restrictedUserAcceptance:
      "Users marked as restricted must accept the change first to have their signatures updated",
    learnMoreRestrictedUsers:
      'Learn more about restricted users <a href="https://www.youtube.com/watch?v=z5XSGuJFxPw" target="_blank">by clicking here</a>.',

    viewEmailSignature: "View email signature",
    applyEmailSignatureDescription:
      "The email signature will be applied to the selected users now.",
    userWillapplyEmailSignatureDescription:
      "Upon confirming this action, the emails will be sent immediately to the users.",
    scheduleEmailSignatureDescription:
      "The appointment can be canceled at any time before the scheduled execution date.",
    scheduleEmailSignatureApplication: "Schedule signature application",
    scheduleEmailSignatureApplicationDescription:
      "The email signature will be applied on the selected date.",

    usersApplyTheirOwnSignature:
      "Request users to fill in their details and apply the signature",
    usersApplyTheirOwnSignatureDescription:
      "The selected users will receive a link via email to fill in their information in the chosen signature template and then apply it to their email.",

    applyEmailSignature: "Apply email signature",
    applyVacationMessage: "Apply vacation message",
    selectSignatureModel: "Select signature model",
    customModels: "Custom models",
    defaultModels: "Default models",
    popular: "Popular",
    deleteModel: "Delete model",
    editModel: "Edit model",
    duplicateModel: "Duplicate model",
    emptySignature: "This signature is empty",
    missingInfoAlertText:
      "One or more selected users are missing some information present in this template.",
    actionTextToCorrectInformation:
      "Click on the user to correct the missing information.",
    editInformation: "Edit information",
    applyModel: "Apply model",
    messageDelayToApply:
      "The signature model may take up to 10 minutes to be effectively implemented.",
    confirmApply:
      "Selected users will have their signature modified. <strong>Do you want to continue?</strong>",
    infoScheduleApply:
      "The signature will only be applied on the date selected in the field below and has a limit of 30 days.",
    scheduleApplication: "Schedule the signature application",
    notifyUsers: "Notify signature change to all users",
    notifyUsersScheduled:
      "Notify signature change to all users after application execution",
    infoNotifyUsers:
      "Restricted users will receive the signature application request email regardless of whether this option is selected or not.",
    signatureModels: "Signature models",
    viewInList: "View in list",
    viewInGrid: "View in grid",
    goToApplication: "Go to application",
    defineModelToNewUsers: "Set model for new users",
    uncheckModelToNewUsers: "Uncheck template for new users",
    automaticApplication: "Automatic application",
    newModel: "New model",
    deleteSignatureModel: "Delete signature model",
    deleteSignatureModelWarning:
      "The deletion does not reflect on the Gmail signature of the users below, however they will not have a signature model assigned in Conecta Suite.",
    defineModelToNewUsersDescription:
      "Define the <b>email signature template that will be automatically applied to new users</b> in your Workspace.",
    confirmModelToNewUsers:
      "Click <span class='font-weight-bold text-uppercase'>Confirm</span> to set this template for all new users in your Workspace.",
    newUser: "New user",
    signatureConfiguredForNewUsers: "Signature configured for new users",
    vacationModel: "Vacation model",
    applySignatureModelInstructions:
      "Choose the users you want to apply and click Apply Email Signature.",
    applyVacationModelInstructions:
      "Choose the users you want to apply and click Apply Vacation Message",
    usersShouldApply:
      "Ask users to fill their own informations and apply that signature model",
    usersShouldApplyInfo:
      "Users will receive an e-mail with a link to fill their informations, preview that signature model, and apply it.",
    availableForPrimary: "Only available for primary emails.",
    turnOnTour: "Enable help with templates",
    turnOffTour: "Disable help with templates",
  },
  usersPage: {
    primaryEmails: "Primary emails",
    allEmails: "All emails",
    secondarySearchBy: "Search by email, username or associated name",
    primarySearchBy: "Search by name or primary email",
    showing: "Showing",
    usersOnTableSelecteds: "table users were selected",
    usersOnPageSelecteds: "users on this page are selected",
    selectAllUsers: "Select all users",
    selectAllUsersInSearch: "Select all users that match this search",
    clickOnTheUserToConfigureSendas:
      "Click on the user name to configure secondary emails for this user.",
  },

  filters: {
    filters: "Filters",
    signatureModelApplied: "Signature model applied",
    signatureModelAppliedDescription:
      "Last signature model applied to the user",
    noSignatureModelSelected: "No signature model selected",
    vacationMessageTemplateApplied: "Vacation Message Template Applied",
    vacationMessageTemplateAppliedDescription:
      "Latest vacation message template applied to the user",
  },

  confirmUpdateTags: {
    successfull: "Update completed successfully!",
    invalidLink: "Oops! The accessed link is no longer valid.",
    editAgain: "Edit Information again",
    updateTags: "Update Information",
    previewAndApply: "Preview and apply signature",
  },

  missingTags: {
    someAreInUsers:
      "Some information from the template is missing in selected users",
    allIsFilled: "All required information is filled",
    aUserAndATag:
      "User {name} does not have the information <b>{tag}</b> filled",
    aUserAndTags: "User {name} does not have the information filled",
    usersAndATag:
      "{num} selected users do not have one piece of information filled",
    usersAndTags: "{num} selected users do not have some information filled",
  },

  retry: {
    waitingGoogle: "Connecting to Google. Please wait a moment.",
    justInstalled:
      "If you just installed the application, this may take a few minutes.",
    ensureScopes:
      'Please ensure you have granted the necessary permissions by visiting the <a href="{link}" target="_blank">Admin console</a>.',
  },

  vacationApply: {
    overwriteWarning:
      "Some of the selected users already have a vacation message configured. Applying a new configuration will overwrite the previously applied message.",
    intro: {
      subtitle: "Know which configurations may be done along the flow",
    },
    model: {
      title: "Message template",
      subtitle: "Define the message that users will use",
      search: "Search for template in All templates",
      filter: "Filter by template type",
      noModelFor: 'No templates matching the search "{search}"',
      noCustomModelFor: 'No custom templates matching the search "{search}"',
      noDefaultModelFor: 'No default templates matching the search "{search}"',
      noResultFor: 'No results matching the search "{search}"',
      searchCustom: "Search for custom template",
      searchDefault: "Search for default template",
      allModels: "All templates",
    },
    configure: {
      title: "Configure message",
      subtitle: "Choose the duration period, among other settings",
      pageTitle: "Vacation message settings",
      description:
        "Configure the period when the automatic vacation message should be active and who should receive this message when contacting the vacationing user.",
      vacationPeriod: "Vacation period",
      userSchedule: "User schedule settings",
      addEvent: "Add vacation event to user calendar",
      eventName: "Vacation ⛱️",
      eventTitle: "Event title",
      selectEventName: "Select event name in calendar",
      restrictions: "Automatic reply restrictions",
      restrictionsTooltip:
        "Restrict the receipt of the automatic vacation message only to the user's contacts and/or only to users within the company's domain",
      contactsOnly: "Send reply only to contacts",
      companyOnly: "Send reply only to company users",
      forwarding: "Forwarding received emails during vacation period",
      forwardingToUser: "Forward emails to another user",
      receiver: "User who will receive the emails",
      selectReceiver: "Choose user to receive the emails",
    },

    absenseSettings: {
      stepTitle: "Vacation Absence Settings",
      stepSubtitle:
        "Add a vacation event and forward incoming emails during your vacation period",
      title: "Vacation Absence Settings",
      subtitle:
        "Add a vacation event to your calendar or forward incoming emails to another user during your vacation period",
      calendarSettings: "User Calendar Settings",
      addHolidayEvent: "Add holiday event to user's calendar",
    },

    startedVacationFlow: {
      title: "Activate the vacation message in Google Workspace",
      subtitle:
        "Configure an automatic vacation response to notify that the user will not be able to respond immediately.",
      setUpAVacationResponder:
        "Automatic response when receiving emails during vacation.",
      forwardingReceived: "Forwarding received emails to another user",
      addHoliday: "Add vacation event to user's calendar",
      autoReply: "Define who will receive the automatic response",
    },
    save: {
      title: "Save changes",
      subtitle: "Confirm and apply the changes",
      gridView: "Switch to grid view",
      groupView: "Switch to group view",
      noUserFor: 'No users found for the value "{search}"',
      searchUser: "Search by name, department, or primary email",
    },
    manageAccess: {
      title: "Manage access",
      subtitle: "Manage access during the user's vacation!",
      description:
        "Maintain full control over your employees' access during the vacation period using Conecta Control.",
      question: "Do you want to block your employees' access?",
      upsellInvitation:
        "Access control is not available for your plan. Discover the available plans and upgrade to controle the users' access.",
      dontBlock: "Don't block",
      block: "Block access during vacation",
      success:
        "Access control during users' vacations was successfully configured.",
      failure: "Failed to configure access control during users' vacations.",
    },
    concludedVacationFlow: {
      title: "Finish",
      subtitle: "Check the details",
      finishFlow: "Vacation configuration completed!",
      finishFlowSubtitle:
        "The complete details of the configured vacation are listed below",
      selectedModel: "Selected model",
      selectedPeriod: "Defined period",
      selectedUsers: "Selected users",
      calendarEvent: "Event saved on the calendar",
      forwardingEmail: "Forwarding emails to",
      templateApplied: "Selected reply template: {modelName}.",
      templateCustomApplied: "The chosen reply template was customized by you.",
      rateExperience: "Rate your experience with our vacation flow",
    },
  },
  signAutomation: {
    activateTitle: "Activate automatic signature reapplication",
    activateSubtitle:
      "With automatic signature reapplication, each user will have their email signature automatically reapplied daily.",
    alertControlPlan:
      "To activate automatic signature reapplication, it is necessary to subscribe to the Security Plan or higher.",
    alertAutomationNotActive:
      "When activating Sign Automation, automatic signature reapplication will be implemented for all company users who have a signature template applied.",
    alertAutomationActive:
      "<b>Sign Automation is active.</b> By deactivating, signatures will no longer be automatically reapplied.",
    activate: "Activate automation",
    deactivate: "Deactivate automation",
    scheduledSignatures: "Scheduled signature applications",
    authorTooltip: "The user who performed the action",
    errorTooltip: "Contact our support to identify a possible failure.",
    signaturePreview: "View applied signature",
    previewSignatureName: "Viewing signature {name}",
    cancelSchedule: "Cancel schedule",
    applyFor: "Applied for",
    successActivate: "Automatic reapplication successfully DEACTIVATED",
    successDeactivate: "Automatic reapplication successfully ACTIVATED",
    error: "Could not change automatic reapplication.",
    successOnCancel: "Schedule cancellation successful",
    errorOnCancel:
      "Could not remove the schedule. Contact Conecta Suite support.",
    cancelTitle: "Cancel the schedule",
    status: {
      created: "Schedule failed",
      scheduled: "Scheduled",
      executed: "Executed",
      deleted: "Canceled",
    },
  },

  tableHeaders: {
    users: "Users",
    department: "Department",
    lastLogin: "Last Login",
    apps: "Applications",
    registrationDate: "Registration date",
    scheduleDate: "Schedule date",
    situation: "Situation",
    author: "Author",
    signature: "Signature",
    actions: "Actions",
  },

  reports: {
    reason: "Reason",
    reasonNotRecorded: "The author did not record a reason.",
  },
  syncUsers: {
    syncUsers: "User synchronization is in progress",
    synchronizingUsers: "Synchronizing users",
  },

  newDashboard: {
    meeting: "Schedule a call",
    meetingDescription: "Talk to one of our specialists",
    hello: "Hello",

    goTocontrolCTA: "Access Conecta Control",
    upsellToControlCTA: "Learn more about Conecta Control",
    controlCTADescription: "Set up shifts easily and efficiently!",

    dashCards: {
      title: {
        signature: "Email signature",
        vacationMessage: "Vacation message",
        vacationActive: "Active vacation",
        controlShifts: "Work shifts",
      },
      description: {
        signature: "Have active email signatures",
        vacationMessage: "Have vacation messages configured",
        vacationActive: "Are absent with active vacation",
        controlShifts: "Are assigned to work shifts in Control",
      },
      subDescription: {
        signature: "<strong>{usersNumber}</strong> users do not have",
        controlShifts: "<strong>{usersNumber}</strong> are not in shifts",
      },
    },
    support: "Support",
    emptyStateAlert:
      "<strong>Attention:</strong> The data shown below is <strong>demonstrative</strong>. Start performing tasks to <strong>replace</strong> it with real data.",
  },

  usersTableMetrics: {
    signature: "Signatures",
    permissions: "Active permissions",
    vacation: "Out of office message",
    delegates: "Delegated emails",
    vacationControl: "Out of office control",
    shifts: "Shifts",
    signModel: "Signature model",
    subtitle:
      "Use the tabs below to view lists of collaborators with different settings.",
  },

  permissions: {
    billing: "Billing",
    inventory: "Digital inventory",
    company_profile: "Company profile",
    manage_admins: "User permissions",
    reseller: "Conecta Partners",
    conecta_control: "Conecta Control",
    conecta_track: "Conecta Track",
    conecta_sign: "Mass application",
    conecta_suite: "User profile",
    sign_automation: "Automatic application",
    reports: "Reports",
  },

  groupCreation: {
    intro: {
      subtitle: "Benefits of using Google Workspace groups",
    },
    createGroup: {
      title: "Create group",
      subtitle: "Define group name, email, and description.",
    },
    configureAccess: {
      title: "Configure access",
      subtitle: "Define participants' access permissions.",
    },
    addParticipants: {
      title: "Add participants",
      subtitle: "Add the users you want to the group.",
    },
    configureSharedDrives: {
      title: "Configure shared drives",
      subtitle: "Add and configure the group's shared drives.",
    },
  },
};
