import { emails } from "@/helpers/variables/contactConecta";
import { MIN_LICENSES } from "@/helpers/variables/backendConstants";

export const successMessages = {
  saves_changes: "As alterações realizadas foram salvas.",
  new_vacation_model: "Modelo de mensagem de férias criado com sucesso.",
  operation_sucess: "Operação realizada com sucesso!",
  signature_applied: "Assinatura aplicada com sucesso!",
  saved_signature: "Assinatura salva com sucesso!",
  template_editor: "As alterações para este modelo de assinatura foram salvas.",
  copy_link: "Link copiado com sucesso",
  vacation_message:
    "Mensagem de férias configurada com sucesso. Em breve as alterações serão aplicadas.",
  delete_vacation_message:
    "A mensagem automática de férias do usuário foi desativada",
  multiple_delete_vacation_message:
    "As mensagens de férias dos usuários foram desativadas",
  tags_update: "informações configuradas com sucesso!",
  set_signature_to_users:
    "As assinaturas de e-mail dos usuários selecionados em breve serão alteradas.",
  reapply_signature_to_users:
    "As informações foram salvas e serão reaplicadas nas assinaturas de e-mail em breve.",
  delete_24hours:
    "Exclusão realizada com sucesso, pode levar até 24h para ser aplicada.",
  apply_24hours:
    "Sucesso! Essa alteração pode levar até 24h para ser aplicada.",
  admin_functions: "Esse usuário já possui permissão para este módulo.",
  partner_term:
    "Tudo certo! Agora você pode acessar as funcionalidades do Conecta Partner",
  delete_template: "O modelo foi excluído com sucesso!",
  update_users_in_batch:
    "As informações dos usuários serão atualizadas em breve",
  update_users: "As informações dos usuários foram atualizadas",
  save_user_in_workspace: "Alterações salvas no Google Workspace!",
  changed_expire_date: "A data de expiração foi alterada com sucesso!",
  new_tag_success: "Informação adicionada com sucesso!",
  delete_tag_success: "Informação excluída com sucesso!",
  email_migration_started: "A migração de e-mails foi iniciada com sucesso",
  migration_in_queue: "A migração de e-mail foi incluída na fila.",
  insert_users_calendars: "A inserção de agendas foi concluída com sucesso.",
  user_created: "O usuário foi criado com sucesso",
  email_sended: "O e-mail foi enviado com sucesso",
  copied_email: "E-mail copiado!",
  SCHEDULE_LICENSE_DELETION:
    "As Licenças serão removidas após o fim das transferências de dados!",
  LICENSE_DELETED: "Licenças removidas com sucesso!",
};

export const errorMessages = {
  delete_template: "Ocorreu um erro ao tentar excluir o modelo!",
  unknown: "Ocorreu um erro, por favor, tente novamente.",
  operation_failure: "Erro ao realizar operação!",
  failure_CSV: "Não existem dados computáveis para exportação.",
  copy_failure: "Ocorreu um erro ao copiar, tente novamente.",
  vacation_message:
    "Não foi possível salvar as configurações de mensagem de férias.",
  delete_vacation_message:
    "Ocorreu um erro ao desativar a mensagem de férias do usuário, tente novamente",
  multiple_delete_vacation_message:
    "Ocorreu um erro ao desativar a mensagem de férias dos usuários, tente novamente",
  tags_update:
    "Erro ao configurar as informações de assinatura, verifique os campos e tente novamente!",
  model_load_pattern:
    "Não foi possível carregar os modelos padrão, por favor, tente mais tarde",
  model_load_custom:
    "Não foi possível carregar os modelos personalizados, por favor, tente mais tarde",
  model_not_found: "Não foi possível carregar o modelo",
  select_template_to_apply:
    "Não foi possível encontrar o modelo selecionado, tente novamente.",
  set_signature_to_users:
    "Não foi possível aplicar as alterações de assinatura, por favor, tente novamente",
  admin_function_403_error:
    "Você não pode mudar a permissão de um Administrador Google.",
  admin_function_409_error:
    "Esse usuário já possui permissão para este módulo.",
  admin_function_sync:
    "Algo deu errado, sincronize os usuários e tente novamente.",
  saved_signature: "Erro ao salvar a assinatura!",
  partner_term: "Ocorreu um erro com sua solicitação, tente novamente",
  notBillingPermission:
    "Contate seu administrador para aquisição de novos planos!",
  minimum_value_not_reached: "O valor mínimo de compra não atingiu R$40",
  invalid_calculation: `Encontramos uma inconsistência nos preços. Contate o suporte para mais informações ${emails.suporte}`,
  invalid_monthly_subscription:
    "Não é possível editar os dados de um plano mensal.",
  invalid_discount: "Não é possível editar o desconto de um plano.",
  diferent_discount_values: `Encontramos uma inconsistência no valor do desconto, contate o suporte para mais informações ${emails.suporte}.`,
  diferent_discount_calculation: `Encontramos uma inconsistência no cálculo de desconto calculado, contate o suporte para mais informações ${emails.suporte}.`,
  reseller_cannot_edit_plan:
    "Planos em Trial e mensais não podem ser editados.",
  reseller_cannot_edit_plan_dates:
    "Não é possível editar as datas de um plano.",
  max_licenses_error:
    "Número de licenças inválido! Não é permitido alterar o número de licenças em planos mensais e trial.",
  NOT_FOUND: "Recurso não encontrado",
  FORBIDDEN: "Ação proibida",
  INVALID_SUBSCRIPTION: "Troca de periodicidade de plano não permitida",
  INVALID_KEY: "Recurso não encontrado",
  key_error: "Plano não encontrado",
  plan_error:
    "Plano inválido, o plano deve estar ativado e visível para ser contratado",
  edit_template_default: "Não é possível editar um modelo padrão",
  ERROR_WHILE_DECODING_DATA:
    "Não foi possível obter as informações do arquivo informado",
  DATE_UPDATE_ERROR: "Erro ao atualizar a data.",
  USER_NOT_FOUND:
    "Usuário não encontrado. Por favor, sincronize os usuários e tente novamente.",
  USER_ID_NOT_FOUND:
    "O ID de alguns usuários não foram encontrados, por favor, sincronize os usuários e tente novamente.",
  CSV_IS_EMPTY:
    "O arquivo informado está vazio, por favor, insira um arquivo válido.",
  failed_precondition: "Serviço de e-mail desabilitado para este usuário",
  FAILED_PRECONDITION: "Serviço de e-mail desabilitado para este usuário",
  conflict_delegate: "Esta delegação já está ativa para este usuário.",
  NOT_IN_CACHE: "Não é possível ler os dados deste CNPJ",
  LIMIT_EXCEEDED: "Tente novamente em um minuto",
  REJECTED_CNPJ: "CNPJ rejeitado pela receita federal",
  INVALID_CNPJ: "CNPJ inválido",
  BAD_REQUEST_CNPJ: "CNPJ não informado",
  INVALID_ARGUMENT:
    "Não foi possível salvar as alterações. O e-mail inserido é inválido ou não foi encontrado. Por favor, verifique e tente novamente.",
  INVALID_SENDAS_ARGUMENT:
    "O e-mail fornecido não é um usuário ou grupo válido, tente novamente.",
  reapply_signature_error:
    "Não foi possível reaplicar a assinatura de e-mail para esse usuário",
  unknown_sendas_error:
    "Não foi possível aplicar o e-mail secundário, tente novamente.",
  USERS_NOT_FOUND: "Os usuários não foram encontrados, tente novamente.",
  INVALID_GOOGLE_ACCESS_TOKEN: `Token de acesso inválido. Favor checar os escopos permitidos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.</br> Se a aplicação foi instalada em menos de 24h, é possível que o Google ainda esteja processando os escopos. Por favor, tente novamente mais tarde.`,
  UNAUTHORIZED_UPDATE_USER_SCOPE: `Não foi possível atualizar os dados no workspace. Favor checar os escopos permitidos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  UNAUTHORIZED_DOMAIN_SCOPE: `Não foi possível sincronizar os domínios do seu workspace. Favor checar os escopos permitidos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  UNAUTHORIZED_USER_APPS_SCOPE: `Não foi possível obter as aplicações do usuário no workspace. Favor checar os escopos permitidos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  UNAUTHORIZED_USER_APPS_LAST_AUTH_SCOPE: `Não foi possível obter a data de última autorização da aplicação no workspace. Favor checar os escopos permitidos no <a href='https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}' target='_blank'>Google Admin Console</a>.`,
  INVALID_COMPANY_CNPJ:
    "Não foi possível concluir a contratação do plano pois a empresa contratante não possui CNPJ cadastrado no sistema.",
  INVALID_MAX_LICENCES: `O número máximo de licenças não pode ser inferior o número de usuários da empresa ou a ${MIN_LICENSES}.`,
  LOG_ERROR:
    "Tivemos um problema para recuperar os registros, tente novamente mais tarde.",
  TAG_CONFLICT: "Já existe uma tag com esse nome.",
  TAG_NOT_FOUND: "Tag não encontrada.",
  GROUP_CONFLICT: "O usuário já está inserido neste grupo",
  SENDAS_ALREADY_EXISTS:
    "O e-mail selecionado já é um e-mail secundário deste usuário",
  MAX_VALUE_EXCEEDED: "O limite de informações personalizads foi excedido",
  DOWNGRADE_BLOCK:
    "Não é possível realizar downgrade de plano na modalidade anual. Por favor, entre em contato via e-mail para experience@conectasuite.com.",
  INVALID_GRANT:
    "URL inválida. Por favor, verifique as permissões e tente novamente.",
  MISSING_CODE:
    "Ocorreu um erro ao tentar obter o token. Verifique as permissões e tente novamente.",
  NO_DOMAIN_ASSOCIATED:
    "Não há nenhum domínio associado à essa conta. Por favor, entre com uma conta associada ao Workspace.",
  MARKETPLACE_APP_NOT_INSTALLED: `O aplicativo não está instalado no seu domínio. Favor instalar o app no <a href="https://workspace.google.com/marketplace/app/control/${process.env.VUE_APP_MARKETPLACE_APP_ID}" target="_blank">Google Workspace Marketplace</a>`,
  NOT_GOOGLE_ADMIN:
    "Você não é um Administrador do Google Workspace ou não tem permissão para acessar o Conecta Suite.",
  COMPANY_BANNED:
    "Acesso suspenso por algum critério técnico ou violação dos termos de uso.",
  MIGRATE_EMAIL_ERROR:
    "Ocorreu um erro ao iniciar a migração de e-mails, tente novamente mais tarde.",
  MAIL_SERVICE_NOT_ENABLED:
    "O serviço de e-mail do colaborador não está habilitado no seu google workspace.",
  USER_DUPLICATED_ERROR: "Este e-mail já está sendo usado por outro usuário!",
  INVALID_DATA:
    "Não foi possível criar o usuário! Verifique os campos e tente novamente.",
  PLAN_EXPIRED:
    "Não foi possível realizar a ação. Verifique seu plano e tente novamente",
  FAILED_TO_UPLOAD_IMAGE:
    "Não foi possível realizar a ação. Verifique seu os escopos estão aceitos ou se você possui o Google Drive e tente novamente.",

  USER_NOT_FOUND_IN_CONTROL:
    "Operação falhou devido a uma inconsistência. Por favor, faça a sincronização dos usuários no Conecta Control e tente novamente.",
  USERS_NUMBER_LIMIT_EXCEEDED:
    "Não foi possível criar este usuário! Verifique seu número de licenças disponíveis no Google Workspace.",
  SHIFTS_NOT_FOUND: "Não foi possível carregar os turnos.",
  CONTROL_NOT_FOUND:
    "Não foi possível carregar informações do Conecta Control.",
  VERIFY_CONTROL: "Verifique o seu Conecta Control.",
  SIGN_IN_CONTROL: "Realize o acesso no Conecta Control para criar turnos.",
  DELETE_CALENDAR_ERROR:
    "Verifique se a agenda está vinculada ao usuário e/ou ele não deve ser o proprietário.",
  USER_GROUPS_ERROR:
    "Erro desconhecido! Não foi possível listar os grupos do usuário.",
  EMAIL_NOT_SENDED:
    "O e-mail não foi enviado! Verifique o destinatário ou tente outro método.",
  SHARED_DRIVE_FORBIDDEN:
    "Você não é o proprietário deste drive ou não pode atribuir este cargo ao usuário.",
  USER_DRIVES_NOT_FOUND:
    "Não foi possível carregar os drives do usuário. Verifique seu plano.",
  HAS_TASK_RUNNING:
    "Já existe uma tarefa de migração em execução para este domínio, aguarde a conclusão e tente novamente",
  FREE_USAGE_LIMIT_EXCEEDED:
    "O limite de uso gratuito foi atingido. Agora é necessário contratar um plano para continuar utilizando a funcionalidade.",
};

export const snackbarMessages = { ...successMessages, ...errorMessages };
