export const common = {
  search: "Pesquisar",
  collaborators: "Colaboradores",
  access: "Acessar",
  for: "por",
  hour: "Hora",
  insertLink: "Inserir link",
  understood: "Entendi",
  availableFromSecurity: "Disponível para planos a partir do security",
  pickADate: "Escolha uma data",
  and: "e",
  pending: "Pendente",
  next: "Próxima",
  currentPlan: "Plano atual",
  currentPlans: "Planos atuais",
  free: "Gratuito",
  freeTrialPeriod: "Gratuito (14 dias)",
  includes: "Inclui:",
  licenseNumber: "Número de licenças",
  recommended: "Recomendado",
  user: "Usuário",
  installment: "Parcela",
  installments: "Parcelas",
  annualPlan: "Plano Anual",
  trialPlan: "Plano trial",
  flexPlan: "Plano mensal",
  freePlan: "Plano gratuito",
  total: "Total",
  licenses: "licenças",
  license: "Licença",
  to: "para",
  notRegistered: "Não cadastrado",
  payment: "Pagamento",
  from: "De",
  until: "Até",
  since: "Desde",
  second: "segundo",
  seconds: "segundos",
  help: "Ajuda",
  requiredField: "Indica um campo obrigatório",
  start: "Começar",
  createUser: "Criar usuário",
  userInfos: "Informações do usuário",
  emailSignatureInfomations: "Informações para assinatura de e-mail",
  emailSignature: "Assinatura de e-mail",
  accessControl: "Controle de acesso",
  selectUser: "Selecionar usuário",
  name: "Nome",
  lastName: "Sobrenome",
  primaryEmail: "E-mail principal",
  secondaryEmail: "E-mail secundário",
  domain: "Domain",
  supportedFormats: "Formatos suportados",
  organizationUnit: "Unidade organizacional",
  image: "Imagem",
  back: "Voltar",
  advance: "Avançar",
  prepare: "Preparar",
  group: "Grupo",
  representation: "Representação",
  addUserToGroups: "Adicionar usuário a grupos",
  workspaceGroups: "Grupos do Workspace",
  workspaceGroupsDescription:
    "Adicione o usuário em grupos para que este possa receber e enviar emails com o e-mail do grupo.",
  confirmDeletion: "Confirmar exclusão",
  confirmGroupDeletion: "Você realmente deseja remover o usuário do grupo",
  addGroupDescription: "Selecione o grupo que deseja inserir o usuário",
  saveAsSecondaryEmail: "Salvar como e-mail secundário do usuário",
  sendEmailAs: "Enviar e-mail como",
  groupAliasAlreadyInUseforUser:
    "Este grupo já é um e-mail secundário deste usuário.",
  requiredEmailService:
    "Válido somente para usuários com serviço de e-mail ativo!",
  inactiveUserInWorkspaceAlert:
    "O serviço de e-mail deste usuário não está ativo! Considere verificar sua licença no Google Workspace.",
  inactiveEmailService: "O serviço de e-mail deste usuário não está ativo",
  configAlternativeEmails: "Configurar e-mails alternativos",
  emailsAliases: "aliases de e-mail",
  configAlternativeEmailsDescription:
    "Adicione e-mails alternativos de e-mail (aliases de e-mail) para que o usuário receba mensagens a partir de outros endereços de e-mail.",
  receiveEmailsLike: "Receba e-mails como",
  addAlternativeEmail: "Adicionar e-mail alternativo",
  confirmDeletionDescription: "Você realmente deseja excluir",
  addAlternativeEmailDescription:
    "Insira o endereço de e-mail alternativo pelo qual o usuário poderá receber mensagens.",
  alternativeEmail: "E-mail alternativo",
  configSecondaryEmail: "Configurar e-mail secundário",
  configSecondaryEmailDescription:
    "Adicione endereços de e-mail para que o usuário use o e-mail padrão dele para enviar mensagens a partir de outros endereços de e-mail.",
  defaultEmail: "E-mail padrão",
  confirmAction: "Você realmente deseja confirmar essa ação?",
  confirmChange: "Confirmar alteração",
  confirmChanges: "Confirmar alterações",
  confirmModify: "Confirmar modificação",
  enterNameFor: "Inisira o nome para",
  descriptionConfirmModification: "Você realmente deseja modificar",
  addEmailAddress: "Adicionar endereço de e-mail",
  secondaryEmailAddressDescription:
    "Adicione endereços de e-mail para que os usuários usem o e-mail padrão dele para enviar mensagens a partir de outros endereços de e-mail.",
  applySecondaryEmail: "Aplicar e-mail secundário",
  optional: "Opcional",
  addSecondaryEmailDescription:
    "Insira o endereço de e-mail que os usuários poderão usar.",
  watchTheVideo: "Assista ao vídeo",
  customizedInformation: "Informações personalizadas",
  subscriptionApp: "Aplicação de assinatura",
  subscriptionAppTooltipText:
    "As assinaturas de e-mail serão adicionadas automaticamente ao final das mensagens do Gmail, como um rodapé.",
  searchModel: "Pesquisar modelo",
  noCustomModelFound: "Nenhum modelo personalizado encontrado",
  noDefaultModelFound: "Nenhum modelo padrão encontrado",
  noModelsSelected: "Nenhum modelo selecionado",
  selectAModel: "Selecione um modelo para visualizar",
  currentSubscriptionTo: "Assinatura atual de",
  subject: "Assunto",
  checkTheNewSignature: "Verifique a nova assinatura de e-mail!",
  viewInTheme: "Ver no tema",
  light: "claro",
  dark: "escuro",
  customized: "Personalizados",
  default: "Padrão",
  noDataAvailable: "Não há dados disponíveis",
  selectedEmails: "E-mails selecionados",
  lastUpdate: "Ultima atualização",
  loading: "Carregando",
  noResults: "Sem resultados",
  linesPerPage: "Linhas por página",
  userAddedToShifts: "adicionado aos turnos, onboarding concluído!",
  insertUserIntoCalendar: "Inserir usuário em agendas da Empresa",
  userCalendars: "Agendas do usuário",
  addUserCalendarsDescription:
    "Adicione o usuário em agendas da Empresa ou de outros usuários para que ele possa conferir os eventos no calendário.",
  schedule: "Agenda",
  description: "Descrição",
  addCalendar: "Adicionar agenda",
  selectCalendars: "Selecione as agendas para adicionar aos usuários.",
  addCalendarDescription:
    'As permissões dos usuários (se podem apenas visualizar, adicionar ou modificar eventos) ainda são configuradas no site do <a href="https://calendar.google.com/">Google Agenda</a>.',
  addCalendarsHelpText:
    "Caso alguma agenda não esteja disponível, atualize a lista aqui",
  searchCalendars: "Buscar agendas",
  calendarsNotFound: "As agendas da empresa não foram encontradas",
  requestFunctionalityScopes:
    "Por favor, aceite os escopos no seu google workspace para utilizar esta funcionalidade.",
  requestFunctionalityScopesToNonAdminUser:
    "Por favor, solicite que seu administrador aceite os escopos para utilizar esta funcionalidade.",
  companyDrives: "Drives da empresa",
  addUserToSharedDrivers: "Adicionar usuário em drives compartilhados",
  addUserToSharedDriversDescription:
    "Adicione o usuário em drives para que este possa acessar informações da empresa ou do setor ao qual está vinculado.",
  addSharedDrive: "Adicionar drive compartilhado",
  addSharedDriveDescription: "Selecione o drive que deseja inserir o usuário",
  companyDrive: "Drive da empresa",
  defineTheRole: "Defina o cargo",
  clickToCopyData: "Clique aqui para voltar e copiar os dados.",
  homePage: "Página inicial",
  viewInFullscren: "Visualizar em tela cheia",
  noDataFound: "Nenhum dado encontrado",
  of: "de",
  viewingModel: "Visualizando modelo",
  viewingModelAs: "Visualizando modelo como",
  thisModelEmpty: "Este modelo está vazio",
  marketplaceInstall:
    "O Conecta Suite não está instalado no seu Google Workspace, instale agora para continuar.",
  goToMarketplace: "Ir para o Marketplace",
  availableIndefinitely: "Disponível por tempo limitado!",
  conectaSign: "Conecta Sign",
  conectaTrack: "Conecta Track",
  conectaTransfer: "Conecta Transfer",
  conectaSuite: "Conecta Suite",
  conectaPartners: "Conecta Partners",
  usersPermissions: "Permissões de Usuários",
  contactUs: "Fale conosco",
  knowPlans: "Conhecer os planos",
  scheduleMeeting: "Agendar uma reunião",
  hello: "Olá",
  letsTalk: "vamos conversar?",
  concluded: "Concluído",
  concludedWithFailures: "Concluído com falhas",
  unfinished: "Não concluído",
  resume: "Retomar",
  failed: "Falhou",
  unknown: "?",
  origin: "Origem",
  destination: "Destino",
  author: "Autor",
  sharedDrive: "Drive compartilhado",
  theStart: "Início",
  theFinish: "Conclusão",
  soon: "Em breve",
  status: "Situação",
  date: "Data",
  unavailable: "Indisponível",
  unavailableOnYourPlan: "Indisponível para seu plano",
  doNotHavePermission: "Você não possui permissão",
  feedbackRatingTitle: "Deixe seu feedback sobre a funcionalidade",
  reversibleAction: "A ação a seguir pode ser desfeita a qualquer momento.",
  irreversibleAction: "A ação a seguir não poderá ser desfeita.",
  exitWithoutSaving:
    "Algumas alterações não foram salvas. Deseja sair sem salvar?",
  sureToContinue: "Você tem certeza que deseja confirmar esta ação?",
  sureToContinueAction: "Você tem certeza que deseja <b>{action}</b>?",
  planDetails: "Detalhes do plano",
  serviceDetails: "Detalhes do serviço",
  service: "Serviço",
  plan: "Plano",
  new: "Novo",
  newPlan: "Novo plano",
  upsellTitle: "Upgrade disponível para sua empresa",
  checkPlans: "Conheça os planos",
  assigned: "Atribuída",
  removed: "Removida",
  tasks: "Tarefas",
  running: "Em andamento",
  finished: "Finalizada",
  closed: "Encerrada",
  pause: "Pausar",
  userSelection: "Seleção de usuário",
  inventory: "Inventário",
  transferEmails: "Transferir e-mails",
  transferDrive: "Transferir drive",
  transferCalendar: "Transferir calendário",
  licences: "Licenças",
  shortly: "Em breve",
  password: "Senha",
  recipientEmail: "E-mail do destinatário",
  choiceDateToSendEmail: "Escolher data para envio do e-mail",
  limitDate: "Limite de 30 dias a partir de hoje",
  dateToschedule: "Data de agendamento",
  sendCopyToMe: "Enviar cópia para mim",
  sendEmail: "Enviar e-mail",
  scheduleSend: "Agendar envio",
  notValidEmail: "O e-mail não é válido",
  roles: {
    organizer: "Organizador",
    fileOrganizer: "Organizador de arquivos",
    writer: "Escritor",
    commenter: "Comentador",
    reader: "Leitor",
  },
  howToUseInformation: "Como usar Informações na sua assinatura de e-mail.",
  email: "E-mail",
  saveOnlyDescription:
    "Salvar as alterações feitas nas Informações sem reaplicar o modelo de assinatura para os usuários que o utilizam",
  saveAndReapplyDescription:
    "Salvar as alterações e reaplicar modelo de assintura com as Informações atualizadas",
  userHasNoModelApplied:
    "Este usuário não possui um modelo aplicado que possa ser reaplicado.",
  editInGoogleDashboard: "Editar no Google",
  somethingWentWrong: "Algo deu errado",
  appConnected: "Aplicação conectada",
  appDisconnected: "Aplicação desconectada",
  disconnectApp: "Desconectar aplicação",
  getLastAuth: "Obter data de última autorização",
  lastAuthDate: "Data de última autorização",
  userAppsDisconnected:
    "O usuário {userName} foi desconectado das seguintes aplicações: {apps}",
  all: "Todos",
  usersSelecteds: "Usuários selecionados",
  details: "Detalhes",
  customizedModel: "Modelo personalizado",
  defaultModel: "Modelo padrão",
  actionCanBeUndone: "Esta ação pode ser desfeita a qualquer momento.",
  selected: "selecionado",
  users: "usuários",
  member: "Membro",
  admin: "Administrador",
  anyType: "Qualquer tipo",
  request: "Solicitar",
  insert: "Inserir",
  remove: "Remover",
  selectLatest1User: "Selecione ao menos 1 usuário para liberar as opções",
  selecteds: "selecionados",
  googleAdmin: "Administrador do google",
  lastSignatureApplied: "Última assinatura de e-mail aplicada no Conecta Suite",
  gmailUsage: "Uso do gmail",
  gmailUsageDescription: "Tem a caixa de email ativa ou inativa",
  associatedName: "Nome associado",
  emailServiceIsDisabled:
    "O serviço de e-mail está desabilitado para esse usuário.",
  userRestrictDescription:
    "A assinatura de e-mail deste usuário só será alterada mediante confirmação do mesmo via e-mail.",
  usersRestrictedsDescription:
    "Um ou mais usuários restritos foram selecionados. A assinatura só será aplicada mediante a confirmação dos mesmos via e-mail.",
  descriptionMakeUserRestricted:
    "Após a aplicação de uma assinatura de e-mail, os usuários restritos só terão sua assinatura alterada mediante aprovação do próprio usuário via e-mail de confirmação.",
  makeThisUserRestrict: "Você deseja tornar esse usuário restrito?",
  removeRestrictionFromThisUser: "Deseja remover a restrição deste usuário?",
  descriptionRemoveRestrictionFromThisUser:
    "Os usuários restritos só terão a assinatura alterada mediante aprovação do próprio usuário via e-mail após realizada a tentativa de aplicação de uma assinatura de e-mail.",
  alertRemoveRestrictionFromThisUser:
    "Desativar a restrição irá permitir que a assinatura do usuário seja alterada a qualquer momento por qualquer colaborador com acesso ao Conecta Sign",
  makeUserRestricted: "Tornar usuário Restrito",
  removeUserRestriction: "Remover restrição do usuário",
  type: "Tipo",
  primary: "Principal",
  secondary: "Secundário",
  userPrimaryEmail: "E-mail principal do usuário",
  userRestrictionChange: "Alterar restrição do usuário",
  requestUserUpdateInfo:
    "Solicitar ao usuário a atualização de suas informações",
  viewAppliedSignature: "Visualizar assinatura aplicada",
  viewAppliedVacationMessage: "Visualizar mensagem de férias aplicada",
  emailSignatureModelPreview: "Pré-visualização do modelo de assinatura",
  vacationModelPreview: "Pré-visualização do modelo de mensagem de férias",
  emptyTagInfo:
    "Caso <b>não tenha alguma informação</b> a tag correspondente ficará <b>em branco</b>",
  bulkActionNotAvailable:
    "Ações em massa e assinaturas de e-mail não estão disponiveis para o seu plano atual, contrate agora para utilizar.",
  availableOnleStandardPlan: "Disponivel apenas para planos Standard",
  youDoNotHavePermissionEmail:
    "Você não possui permissão para gerenciar assinaturas de e-mail.",
  requestAccessToAdmin: "Solicite ao administrador para liberar seu acesso!",
  customizeColumns: "Personalizar colunas",
  customizeColumnsDescription: "Controle a exibição até 8 colunas simultâneas",
  moveColumnUp: "Mover coluna para cima",
  moveColumnDown: "Mover coluna para baixo",
  toThe: "aos",
  notInformed: "Não informado",
  alertAboutChangePayment:
    "Verifique se o cliente preencheu as informações do cartão no Superlógica antes de alterar o método. Dúvidas, contate financeiro@conectasuite.com",
  aboutChangePayment: "Esta alteração não gera mudança no Superlógica!",
  past7Days: "Últimos 7 dias",
  past3Months: "Últimos 3 meses",
  past6Months: "Últimos 6 meses",
  external: "Externo",
  backToGmail: "Voltar para Gmail",
  userHasNoGroups:
    "Este usuário não pertence a nenhum grupo do Google Workspace.",
  hasDriveScopePending:
    "Esta funcionalidade não está ativa devido a escopos pendentes na aplicação Conecta Suite.",
  noUserPleaseSelect:
    "Nenhum usuário selecionado, por favor, selecione ao menos um usuário.",
  selectStartEndDate: "Selecionar datas de início e de fim",
  startEndDate: "Datas de início e de fim",
  enterInContact: "Entrar em contato",
  vacationMessages: "Mensagem de férias",
  activated: "ATIVADO",
  deactivated: "DESATIVADO",
  seeInfo: "Ver informações",
  models: "Modelos",
  seePlan: "Ver plano",
  exit: "Sair",
  seeUser: "Ver usuário",
  shortcut: "Atalho{plural}",
  intro: "Introdução",
};
