import { common } from "./common";
import { MIN_LICENSES } from "@/helpers/variables/backendConstants";

export default {
  languages: {
    ptBR: "Português",
    en: "Inglês",
    es: "Espanhol",
  },

  tour: {
    vstep99:
      "Crie sua assinatura de e-mail facilmente com um modelo pronto e totalmente personalizável.",
    vstep99Title: "Comece com um modelo",
    welcome: "Boas-vindas ao",
    startTour: "Iniciar tour",
    skipTour: "Continuar sem tour",
  },

  TRIAL: "trial",
  FLEX: "mês",
  ANNUAL: "ano",

  action: {
    export: "Exportar",
    knowPlans: "Quero conhecer os planos",
    talkToTeam: "Conversar com a equipe",
    seePlans: "ver planos",
    cancel: "Cancelar",
    buy: "Comprar",
    confirm: "Confirmar",
    clearFields: "Limpar campos",
    clear: "Limpar",
    save: "Salvar",
    saveChanges: "Salvar alterações",
    saveOnly: "Apenas salvar",
    saveAndReapply: "Salvar e reaplicar",
    reportError: "Reportar erro",
    ok: "OK",
    skip: "Pular",
    continue: "Continuar",
    search: "Buscar",
    knowMore: "Saiba mais",
    addGroup: "Adicionar grupo",
    close: "fechar",
    delete: "Excluir",
    edit: "Editar",
    makeDefault: "Tornar padrão",
    next: "Próximo",
    previous: "Anterior",
    clickingHere: "Clicando aqui",
    saveAndNext: "Salvar e avançar",
    applyAndNext: "Aplicar e avançar",
    reloadUserInfo: "Recaregar informações",
    assignLicense: "Atribuir licença",
    loginGoogle: "Fazer login com o Google",
    loginMicrosoft: "Fazer login com a Microsoft",
    aboutScopes: "Entenda os escopos solicitados",
    installApp: "Instale o App no seu Google Workspace",
    acceptScopes: "Aceitar escopos",
    minimize: "Minimizar",
    finish: "Finalizar",
    viewAndSend: "Visualizar e enviar",
    saveAndReapplySignature: "Salvar e reaplicar assinatura",
    sync: "Sincronizar",
    disconnect: "Desconectar",
    create: "Criar",
    newWorkspaceGroup: "Novo grupo do Workspace",
    transferFilesFromDrive: "Transferir arquivos do Drive",
    migrateEmails: "Migrar e-mails",
    updateUsersViaCSV: "Atualizar usuários pelo CSV",
    download: "Baixar",
    clearSelection: "Limpar seleção",
    apply: "Aplicar",
    requestUpdateUsersInfo:
      "Solicitar aos usuários a atualização de suas Informações",
    requestUsersUpdateInfoAndApply:
      "Solicitar aos usuários que apliquem o modelo de assinatura e atualizem suas Informações",
    calendarsToUsers: "Calendários aos usuários",
    removeVacationMessage: "Remover mensagem de férias",
    selectAllUsersOnThisPage: "Selecionar todos os usuários desta página",
    send: "Enviar",
    update: "Atualizar",
    preview: "Visualizar",
    transferAndNext: "Transferir e avançar",
    applyModel: "Aplicar modelo",
    start: "Começar",
    understand: "Entendi",
    reloadShifts: "Recarregar turnos",
    createShifts: "Criar turnos",
    applyVacationMessage: "Aplicar mensagem",
    applySignature: "Aplicar assinatura",
    configVacationMessage: "Configurar férias",
  },

  common,

  scopesDialog: {
    title:
      "Para completar seu login, aceite todas as permissões do Conecta Suite no Google Admin Console.",
    permissions:
      "Precisamos dessas permissões para oferecer uma experiência completa.",
    privacy:
      "Sua privacidade e segurança são nossas prioridades, e você pode revisar as permissões a qualquer momento.",
    faq: `Se tiver dúvidas, acesse o <a href="https://conectasuite.com/perguntas-frequentes/escopos-google-workspace/" target="_blank"><b>FAQ</b></a> ou entre em contato conosco.`,
    acceptAll: `Acesse o Google Admin Console e clique em "Permitir acesso" para prosseguir.`,
    goToAdminConsole: "Ir para o painel Google Admin Console",
    goToPanel: "Ir para o painel",
  },

  companyProfile: {
    companyProfile: "Perfil da Empresa",
    companyInfo: "Informações da empresa",
    companyName: "Nome da empresa",
    cnpj: "CNPJ",
    email: "E-mail",
    site: "Site",
    phone: "Telefone",
    phoneNumber: "Número de telefone",
    phoneHint:
      "Insira um número de telefone preferencialmente com o DDI, DDD e número.",
    mainPhone: "Telefone principal",
    corporate: "Razão social",
    financeEmail: "E-mail do financeiro",
    adminEmail: "E-mail do admin",
    neighborhood: "Bairro",
    address: "Endereço",
    number: "Número",
    complement: "Complemento",
    city: "Cidade",
    state: "Estado",
    country: "País",
    zipCode: "CEP",
    partner: "Parceiro atual",
    partnerCode: "Código de parceiro",
    uploadLogo: "Alterar logo da empresa",
    uploadBanner: "Alterar banner da empresa",
    language: "Idioma",
    completeAddress: "Endereço completo",
    addressExample: "Av. Nome de exemplo, 1234",
    addressHint:
      "Dica: Insira o logradouro por extenso e o número separado por vírgula.",
    reapplyUnavailablePlan:
      "A reaplicação de assinatura de e-mail está disponivel somente para os planos Standard +. Contrate agora para utilizar.",
    reapplyUnavailablePermission:
      "Você não possui permissão para gerenciar assinaturas de email. Solicite ao administrador para liberar seu acesso!",
    reapplyDialogTitle:
      "Deseja reaplicar os modelos de assinatura com os novos valores?",
    reapplyDialogSubtitle:
      "As alterações serão aplicadas nos modelos listados abaixo:",
    reapplyHint:
      "<b>Dica:</b> Você também pode gerenciar quais modelos devem ser reaplicados incluindo ou removendo-os do campo abaixo:",
    affectedModels: "Modelos afetados",
    signatureModelsAlert:
      "Modelos de assinatura podem levar até 10 minutos para serem efetivamente aplicados.",
    noSignatureModelsAffected:
      "Não há modelos afetados pelas informações alteradas.",
    confirmInfoChanges: "Confirmar alterações das informações?",
    companyInfoTitle: "Informações da empresa",
    companyInfoSubtitle: "Atualize e visualize informações da empresa.",
    companyContactInfoTitle: "Informações de contato",
    companyContactInfoSubtitle:
      "Atualize informações de contato e redes sociais.",
    companyMarketingInfoTitle: "Informações de marketing",
    companyMarketingInfoSubtitle: "Configure banner, slogan e mais.",
    companyCustomInfoTitle: "Informações personalizadas",
    companyCustomInfoSubtitle: "Adicione quaisquer informações que desejar.",
    companyBillingTitle: "Faturamento",
    companyBillingSubtitle: "Gerencie seus dados de pagamento.",
    companyPlansInfoTitle: "Planos e cobrança",
    companyPlansInfoSubtitle: "Verifique seu plano e histórico de cobrança.",
    contactInfo: "Informações de contato e redes sociais",
    companyContactTags: {
      phone2: "Telefone 2",
      phone3: "Telefone 3",
      cellphone: "Celular",
      social_media_whatsapp: "WhatsApp",
      social_media_facebook: "Facebook",
      social_media_linkedin: "LinkedIn",
      social_media_twitter: "X (Twitter)",
      social_media_youTube: "YouTube",
      social_media_instagram: "Instagram",
    },
    marketingInfo: "Informações de marketing",
    companyMarketingTags: {
      banner_link: "Link do banner",
      slogan: "Slogan",
      trading_name: "Nome fantasia",
    },
    companyMarketingTagsHint: {
      banner_link:
        "Adicione um link para onde o banner deve redirecionar ao ser clicado.",
    },
    customInfo: "Informações personalizadas",
    customInfoTooltip:
      "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    addNewInfo: "Adicionar nova informação",
    customInfoExceeded: "O limite de informaçõess personalizadas foi excedido",
    customInfoTags: {
      company_custom_1: "Informação personalizada 1",
      company_custom_2: "Informação personalizada 2",
    },
    newCustomTag: "Nova informação personalizada",
    deleteCustomTag: "Excluir informação personalizada",
    confirmDeleteCustomTag:
      "Deseja confirmar a exclusão da informação <strong>{tag}</strong>?",
    billingInfo: "Informações de Faturamento",
    billingHistory: "Histórico de cobrança",
  },

  plansStatus: {
    ACTIVE: "Ativo",
    TRIAL: "Trial",
    TRIAL_EXPIRED: "Expirado",
    DISABLED: "Desativado",
    EXPIRED: "Expirado",
    SUSPENDED: "Suspenso",
    CANCELLED: "Cancelado",
    CANCELLATION_DATE: "Cancelado",
  },

  planPeriodDescription: {
    ANNUAL: "anual",
    FLEX: "mensal",
    QUARTELY: "trimestral",
    SEMESTER: "semestral",
    BIENNIUM: "bienal",
    EXTRA: "licenças extras",
    FREE: "gratuito",
    TRIAL: "trial",
    ONCE: "único",
  },

  companyPlansInfo: {
    myPlans: "Meus planos e serviços",
    knowHowToPurchase: "Saiba como contratar um plano para sua empresa",
    licensesNumber: "Número de licenças",
    maxLicensesNumber: "Número máximo de licenças:",
    originalPricePerLicense: "Valor original por licença",
    originalPrice: "Valor original",
    originalPricePeriod: "Valor original por período:",
    userMonth: "/usuário/mês",
    pricePerLicenseWithDiscount: "Valor por licença com desconto:",
    priceWithDiscount: "Valor com desconto:",
    totalPrice: "Valor total:",
    installments: "Parcelamento:",
    appliedDiscounts: "Desconto aplicado:",
    purchaseDate: "Data de contratação:",
    creationDate: "Criação do plano:",
    initBilling: "Início da cobrança:",
    endTrial: "Encerramento do trial:",
    initTransaction: "Inicio da fatura corrente:",
    endTransaction: "Fim da fatura corrente:",
    nextBilling: "Próxima cobrança:",
    renewalDate: "Renovação do contrato:",
    endDate: "Data de encerramento:",
    autoRenewal: "Renovação automática",
    paymentMethod: "Método de pagamento:",
    seePlansDetail: "Ver detalhes do plano",
    successEditPlan: "Sucesso ao editar o Plano",
    errorEditPlan: "Erro ao editar o Plano",
    TwoWeeksFree: "Gratuito por 14 dias",
    billingInfo: "Informações de cobrança",
    savingsOf: "Economia de",
    discountApplied: "Desconto aplicado de",
    changePaymentMethod: "Alterar método de pagamento",
    paymentChangedTo: "Será alterado para:",
  },

  imageUploader: {
    addImage: "Adicionar Imagem",
    selectImageOrUrl: "Digite uma URL ou faça upload da imagem",
    selectImage: "Selecionar imagem",
    selectImageHint: "A imagem será salva no seu Google Drive",
    imageUrl: "URL da Imagem",
  },

  textFieldRules: {
    requiredCNPJ: "CNPJ é obrigatório",
    insertingCNPJ: "inserindo CNPJ..",
    requiredEmail: "E-mail é obrigatório",
    invalidEmail: "E-mail inválido",
    passwordsDoNotMatch: "As senhas não coincidem",
    mustContainCapitalLetters: "Deve conter letras maiúsculas",
    mustContainLowercaselLetters: "Deve conter letras minúsculas",
    mustContainsNumbers: "Deve conter números",
    mustContainSpecialCharacters: "Deve conter caracteres especiais",
    requiredField: "Campo obrigatório",
    minCharacters: "Número mínimo de caracteres",
    maxCharacters: "Número máximo de caracteres",
    imageTooLarge: "Imagem muito grande",
    maxXCharacters: "Máximo de {number} caracteres",
    max20characters: "Máximo de 20 caracteres",
    max25characters: "Máximo de 25 caracteres",
    max60characters: "Máximo de 60 caracteres",
    max1490characters: "O valor não pode ter mais que 1490 caracteres",
    specialCharacters: "O campo não pode conter caracteres especiais",
    noSpaceCharacters: "O campo não pode conter somente espaços",
    fieldLessThan: "O campo deve ser menor que",
    cannotContainOnlySpaces: "O campo não pode conter somente espaços",
  },

  navbar: {},

  sidebar: {
    home: "Início",
    techDiagnosis: "Diagnóstico tech",
    patnerPanel: "Painel do parceiro",
    clients: "Clientes",
    billing: "Faturamento",
    plans: "Planos",
    services: "Serviços",
    notificationCenter: "Central de notificações",
    clientsBans: "Clientes banidos",
    toManage: "Gerenciar",
    onboardingUser: "Criar usuário",
    company: "Empresa",
    profile: "Perfil",
    inventory: "Inventário digital",
    reports: "Relatórios",
    general: "Gerais",
    accounts: "Contas de usuário",
    permissions: "Permissões",
    emailSignatures: "Assinaturas de e-mail",
    vacationMessages: "Mensagem de férias",
    signAutomation: "Aplicação automática",
    control: "Controle",
    conectaControl: "Conecta Control",
    hirePlan: "Contratar um plano",
    offboardingUser: "Remover usuário",
    vacationMessage: "Mensagem de férias",
    assignVacationMode: "Atribuir modo férias",

    createUser: "Criar usuário",
    removeUser: "Remover usuário",
    feedbackGroupCTA: "Participe Agora",
    upgradeCTA: "Fazer Upgrade",
    vacation: "Férias",
    controlShifts: "Turnos de trabalho",

    basic: "Tenha acesso a mais ferramentas com o Plano Security!",
    standard: "Tenha acesso a mais ferramentas com o Plano Security!",
    security: "Tenha acesso a mais ferramentas com o Plano Enterprise!",
    enterprise:
      "Junte-se ao nosso grupo de usuários que fornecem feedback e garanta vantagens exclusivas!",
  },

  hirePlan: {
    economyOf: "Economia de ",
    paymentPlan: "Plano de pagamento",
    paymentMinimumValue: "Valor mínimo de compra de R$40 não foi atingido! ",
    decreaseLicenseRule:
      "O número de licenças não pode ser inferior ao número de usuários da empresa",
    paymentMinimumValueTextforFlex:
      "Selecione o plano de pagamento anual para continuar",
    paymentMinimumValueTextforAnnual:
      "Adicione licenças extras para continuar nesta modalidade.",
    goBackToPlans: "Retornar para planos",
    annualPayment: "Pagamento Anual",
    monthlyPayment: "Pagamento mensal",
  },

  billing: {
    initBilling: "Início da cobrança",
    discountOf: "Desconto de",
    fromOriginalPrice: "do valor original",
    goToSuperLogica: "Ir para SuperLógica",
  },

  selfCheckout: {
    minLicensesInfo: `Para assegurar a qualidade dos serviços oferecidos e a eficiência do suporte ao cliente, a Conecta Suite estabelece a obrigatoriedade de contratar o mínimo de ${MIN_LICENSES} licenças.`,
    oneTimeCharge: "Cobrança única",
    agileImplementation: "Implementação Ágil",
    checkServiceDescription:
      "Confira o que a Implementação Ágil {name} oferece.",
    standardImplementationHint:
      "Implementação de duas assinaturas de email personalizadas com a cara da sua empresa.",
    securityImplementationHint:
      "Com a implementação ágil, nós faremos um tour pelas ferramentas do conecta Suite, explicação de detalhes importantes para a utilização otimizada do Conecta Control, funcionamento dos bloqueios do Conecta Control e relatórios para análise de ações na plataforma. Além disso, o time da conecta cria duas assinaturas de email personalizadas com a cara da sua empresa.",
    implementServiceContact: "Contato para implementação de serviço",
    implementServiceContactDescription:
      "O nosso time entrará em contato para realizar a implementação ágil do Conecta Suite. Se preferir, entre em contato pelo email <strong>implementacao@conectasuite.com</strong>",
    implementService: "de serviço de implementação",
    cart: "Carrinho",
    licensesTipForAnnual:
      "Defina um número de licenças adicionais para a contratação do plano na modalidade Anual. O menor número possível corresponde ao número de usuários do seu Google Workspace.",
    creditCardPaymentInstructions:
      "pague utilizando cartão de crédito via Superlógica",
    bankSlip: "Boleto",
    bankSlipPaymentTitle: "Contratação realizada!",
    receiveBankSlipEmail: "Receba o boleto para pagamento via e-mail",
    orderDetails: "Dados do pedido",
    bankSlipDueDate: "Vencimento do boleto",
    paymentType: "Forma de pagamento ",
    creditCard: "Cartão de crédito",
    finishBuy: "Finalizar Compra",
    clearCart: "Limpar Carrinho",
    removePlanFromCart: "Remover plano do carrinho",
    confirmPlanPurchaseText: "Sim, contratar este plano",
    implemantationHelp:
      "Gostaria da ajuda do time Conecta Suite Para fazer a implementação",
    goToPayment: "Seguir para pagamento",
    confirmNewPlan: "Confirmar novo plano",
    overwriteCurrentPlan:
      "Você já possui um ou mais produtos do plano que você deseja adquirir. Ao prosseguir, você concorda que o plano atual seja desativado e o novo contratado.",
    newPlan: "Plano novo",
    overwriteCurrentPlanAlert:
      "Alguns produtos do seu plano atual não estão inclusos no plano que você deseja contratar. Você deseja desativar o plano atual e contratar o novo plano?",
    trialEnded: "Seu período de testes terminou",
    trialEndedText:
      "Adquira um plano para continuar aproveitando todas as funcionalidades dos produtos Conecta Suite",
    getBasicAfterTrialEnded: "Após encerramento do teste (a partir de",
    simulationAlert:
      "Por motivos de segurança, não é permitida a contratação de planos durante a simulação de login. <br /> Instrua um administrador do Google desta empresa a realizar a operação por essa tela, ou realize a partir da pagina do cliente no painel Conecta",
    notApplicable: "Não se aplica",
    helpCardFeatureListTitle1:
      "Acesse o SuperLogica e realize o pagamento na plataforma",
    helpCardFeatureListSubtitle1:
      "Sua conta no SuperLogica foi criada, use seu e-mail para acessar o site! Os dados preenchidos no SuperLógica serão utilizados para a cobrança do plano.",
    helpCardFeatureListTitle2: `Você irá receber em breve um e-mail para realizar o pagamento por cartão de crédito!`,
    helpCardFeatureListSubtitle2: `Caso você não tenha sido redirecionado, <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">clique aqui</a>.`,
    helpCardFeatureListTitle3: `Não se preocupe! Enviaremos um e-mail confirmando assim que recebermos seu pagamento!`,
    helpCardFeatureListSubtitle3: `Para sua segurança, não salvamos dados do seu cartão de crédito em nosso banco de dados ou em qualquer lugar da aplicação.`,

    helpBankSlipFeatureListTitle1:
      "As informações da contratação serão enviadas para os e-mails cadastrados.",
    helpBankSlipFeatureListSubtitle1:
      "O e-mail contém as informações importantes do plano contratado, forma de pagamento, valor por licença e data de início da cobrança.",
    helpBankSlipFeatureListTitle2: `Em breve você irá receber um e-mail contendo o boleto para realizar o pagamento.`,
    helpBankSlipFeatureListSubtitle2: `O boleto também estará disponível no SuperLogica. Você pode acessar a área do cliente <a href="https://brlgestao.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank">clicando aqui</a> e informando o seu e-mail.`,
    helpBankSlipFeatureListTitle3:
      "Você já possui acesso às ferramentas! Fique a vontade para utilizá-las.",
    helpBankSlipFeatureListSubtitle3: "",

    hirePlanSnackbarPrefix: "O plano ",
    hirePlanSnackbarSuffix:
      " foi contratado com sucesso. Um e-mail de confirmação será enviado com o remetente",
    hireBasicPlanRule:
      "Este plano estará disponível para contratação após o encerramento do plano em Trial",
    choosePlanPaymentType: "Escolha o plano de pagamento:",
    trialPeriod: "Período de teste",
    beingRedirectdToSL: "Você será redirecionado para o SuperLogica em",
    helpWithPaymentText: "Ficou com dúvidas em relação ao pagamento ou planos?",
    accessPageSuportText: `Envie um e-mail para <a href="mailto:financeiro@conectasuite.com" target="_blank">
      financeiro@conectasuite.com
    </a> ou consulte nossa página de suporte e tire suas dúvidas.`,
    almostThere: "Você está quase lá!",
    plansDescriptions: {
      basic: {
        availability: "Disponível para Google Workspace (G Suite)",
        individualSignatures:
          "Crie, edite e aplique assinaturas de e-mail em HTML de forma individual para os usuários",
        absenceManagement:
          "Gerencie mensagens de férias, feriado e ausência para cada usuário",
        groupManagement:
          "Adicione usuários em grupos de e-mail, agendas e drives compartilhados",
        externalEmail:
          "Permita que seus colaboradores recebam e enviem mensagens a partir de outros endereços de e-mail",
        delegatedManagement:
          "Delegue permissões de gerenciamento para outros usuários no painel simplificado da Conecta Suite",
        adminReport:
          "Relatório detalhado com pontos de melhoria do painel Admin Google Workspace",
        userSetup:
          "Cadastros e configurações simplificadas de novos usuários (novo colaborador)",
        accessSuspension: "Suspensão de acessos do usuário (ex-colaborador)",
      },
      standard: {
        availability: "Disponível para Google Workspace (G Suite)",
        basicBenefits: "Benefícios do plano Basic",
        bulkSignatures:
          "Gerencie assinaturas de e-mail em massa via editor avançado",
        createSignatureTemplates:
          "Crie uma galeria de modelos de assinaturas de e-mail ilimitados para a sua empresa",
        scheduledUpdates:
          "Agende atualização da assinatura de email para datas comemorativas",
        absenceSignatures:
          "Crie, edite e aplique assinaturas de mensagens de férias, feriado e ausência em massa ou individual via editor avançado",
        signatureTemplates: "Acesse modelos prontos de assinaturas de e-mail",
        connectedApps:
          "Relatório de aplicativos conectados ao email corporativo dos usuários",
        delegationExpiration:
          "Defina expiração da delegação de acesso à caixa de e-mail",
      },
      security: {
        availability:
          "Disponível para Google Workspace (G Suite) e Microsoft 365",
        basicBenefits: "Benefícios do plano Basic",
        standardBenefits: "Benefícios do plano Standard",
        autoReapply: "Reaplicação automática de assinaturas de e-mail",
        accessControl:
          "Limite o acesso dos colaboradores ao ambiente digital da empresa por horário, sistema operacional, navegador de internet, DNS, VPN e IP",
        customShifts:
          "Crie regras de acesso com turnos personalizados ilimitados",
        deviceDisconnect:
          "Desconecte o colaborador de todos os dispositivos no horário configurado automaticamente ou com apenas um clique",
        licenseDeactivation: "Desativação de licenças (ex-colaborador)",
        loginReport:
          "Relatório de acessos de logins e logouts do e-mail corporativo",
        fileSharingReport:
          "Relatório de compartilhamento de arquivos corporativos",
        customLogin:
          "Tela de login personalizada com informações e identidade visual da empresa",
        migrationBackup:
          "Migre e-mails e arquivos do Drive entre usuários e grupos para fazer backup",
      },
      enterprise: {
        availability:
          "Disponível para Google Workspace (G Suite) e Microsoft 365",
        basicBenefits: "Benefícios do plano Basic",
        standardBenefits: "Benefícios do plano Standard",
        securityBenefits: "Benefícios do plano Security",
        seniorIntegration: "Integração com Senior Sistemas",
        vrGenteIntegration: "Integração com VR Gente (Pontomais)",
        activeDirectory: "Integração com Active Directory (AD)",
        tangerinoIntegration: "Integração com Sólides Tangerino",
        otherIntegrations: "Integrações com outros sistemas",
      },
    },
    serviceDescriptions: {
      standard: {
        adjustmentsAndGoodPractices: "Ajustes e boas práticas",
        adjustmentsUsers: "Ajustes de usuários no Workspace e Conecta Suite",
        signatureConfig:
          "Configurações para aplicação das assinaturas e delegações necessárias",
        goodPracticesEmail:
          "Boas práticas de envio de mensagens e configurações de assinatura",
        spamOrientation:
          "Validações e orientações para redução  de problemas com SPAM",
        infoSecurity: "Boas práticas de segurança da informação",
        workspaceTraining:
          "Orientações e treinamento para criação dos grupos de e-mail no Google Workspace",
        signatures: "Assinaturas de e-mail",
        signatureModels:
          "Desenvolvimento de 2 modelos de assinatura personalizados",
        applyInBatch: "Aplicação da 1ª assinatura em todos os usuários",
      },
      security: {
        controlAccessConfig: "Configurações e ativação do controle de acesso",
        shifts: "Definição e configurações de turnos",
        controlActivation: "Configuração e ativação do Conecta Control",
        enterpriseIntegrations: "Integrações - Conecta Suite Enterprise",
        adIntegration: "Configuração e ativação AD Integration",
        sistemIntegration:
          "Configuração e ativação da integração com sistema de ponto",
      },
    },
    confirmingNewPlan: {
      youAreAboutToLose: "Trocando de plano você está perdendo:",
      wantBenefits: "Quero continuar com meus benefícios",
      dontWantBenefits: "Eu entendo e não quero os meus benefícios",
      beforeContinuing: "Antes de prosseguir...",
      howDoYouRate: "Como você avalia seu plano atual?",
      tellUsTheReason: "Conte-nos o motivo da desistência do seu plano.",
    },
    churnReasons: {
      tooExpensive: "Muito caro: O custo é muito alto.",
      notUsed: "Não utilizo: Pagando por recursos que não são utilizados.",
      foundCheaper: "Concorrência: Encontrei uma alternativa mais em conta.",
      foundBetter: "Concorrência: Encontrei uma alternativa melhor.",
      noLongerNeeded: "Não preciso mais: Serviços não são mais necessários.",
      difficultToUse: "Experiência ruim: Difícil de usar ou gerenciar.",
      billingProblems: "Cobrança: Problemas com cobrança ou pagamentos.",
      notConfident: "Segurança: Não confia nos recursos de segurança.",
      lackOfIntegrations: "Integrações: Precisa de integrações adicionais.",
      otherReason: "Outro motivo:",
    },
    annualDowngradeBlock:
      "Não é possível realizar downgrade de plano na modalidade anual. Por favor, entre em contato via e-mail para experience@conectasuite.com.",
    subscriptionTypeChangeBlock:
      "Troca de periodicidade de plano não permitida.",
  },

  purchaseLoading: {
    savingPlanInfo: "Salvando informações do seu novo plano...",
    youBeeingRedirected:
      "Você será redirecionado(a) para concluir o pagamento...",
    redirectingIn: "Você será redirecionado em ",
  },

  companyInformation: {
    missingCNPJAlert:
      "Esta empresa <b>não possui CNPJ cadastrado</b>. Para prosseguir com a compra é necessário preencher as informações obrigatórias.",
    billingInfo: "Informações de Faturamento",
  },

  advicePermissionDialog: {
    companyInfoTitle: "Sem permissão de acesso",
    companyInfoMessage:
      "Você não tem permissão para acessar o Perfil da Empresa. Peça para um administrador concluir o cadastro desta empresa antes de fechar um plano.",
  },

  companyProfileForm: {
    saveAndReaply:
      "Salvar as alterações e reaplicar assinaturas com a infomação atualizada",
    saveWithoutReapply:
      "Salvar as alterações das informações sem reaplicar as assinaturas",
  },

  comparativePlan: {},

  periodicity: {
    monthly: "Mensal",
    annual: "Anual",
    ANNUAL: "anual",
    FLEX: "mensal",
    month: "mês",
    year: "ano",
    annualPayment: "Anual (Pagamento anual)",
  },

  errorMessages: {
    registerPlan: "Ops! Erro ao registras informações do plano",
    totalCalc: "Erro ao calcular valor total",
    updateCompanyData: "Erro ao atualizar as informações da empresa",
    fillingRequiredFields: "Preencha os dados obrigatórios",
    USER_NOT_FOUND: "Usuário não encontrado",
    USER_GOOGLE_ADMIN: "O usuário é um administrador Google",
    USER_ALREADY_SUSPENDED: "O usuário já está suspenso",
    USER_ALREADY_ACTIVE: "O usuário já está ativo",
    setSignatureToUsers:
      "Não foi possível aplicar as alterações de assinatura, por favor, tente novamente",
    FREE_USAGE_LIMIT_EXCEEDED:
      "O limite de uso gratuito foi atingido, é necessário contratar um plano para continuar utilizando a funcionalidade.",
    requestSent: "Erro ao enviar solicitação",
    updateTags: "Erro ao realizar atualização das informações",
  },

  successMessages: {
    dataUpdated: "Dados atualizados com sucesso",
    setSignatureToUsers:
      "As assinaturas de e-mail dos usuários selecionados em breve serão alteradas.",
    scheduleSignature:
      "O agendamento foi feito para: {date}. Acompanhe os agendamentos em 'Aplicação automática'.",
    requestSent: "Solicitação enviada com sucesso!",
    templateApplied: "Operação concluída com sucesso!",
  },

  techDiagnosis: {
    title: "Diagnóstico Tech",

    sessions: {
      generalInfo: "Informações gerais",
      economyInfo: "Economia",
      emails: "E-mails",
      security: "Segurança",
    },

    fillResearchValues:
      "Preencha os dados sobre o Google Workspace para verificar valores.",
    goToGoogleWorkspace: "Acessar Google Workspace",
    chatSupport: "Suporte via chat",
    analyzingCompany: "Estamos analisando sua empresa.",
    companyInOrder: "Sua empresa está em ordem.",
    companyHasPointsOfImprovement:
      "Identificamos pontos de melhoria para sua empresa.",
    companyHasPointsOfAttention:
      "Identificamos pontos de atenção para sua empresa.",
    getAnAnalysis: "Receba uma analise da sua empresa.",
    kindUsers: "usuários",
    kindUser: "usuário",
    totalPrice: "Total: R$ {price} mensais",
    totalPriceTooltip:
      "Identificamos que sua empresa pode ter um total de custo desnecessário de até R${price} mensais.",

    workspaceResearch: {
      almostThere: "Quase lá...",
      answerQuestionsBellow:
        "Para um melhor resultado do diagnóstico, responda as perguntas abaixo:",
      partnerMethodLabel: "Sua contratação do Google Workspace é:",
      renewalDateLabel: "Qual a data de renovação do contrato vigente?",
      priceLicenseLabel: "Qual o valor por licença? (R$ usuário/mês)",
      directWithGoogle: "Diretamente com a Google",
      authorizedPartner: "Via Parceiro Autorizado",
      ruleMaxValue: "O valor não pode ser maior que 1000.",
      ruleMinValue: "Informe um valor válido.",
    },

    workspaceInfo: {
      title: "Google Workspace",
      missingData:
        "Dados não informados: Preencha os dados do Google Workspace para receber um diagnostico apropriado.",
      editData: "Editar dados",
      fillData: "Preencher dados",
      goToGoogleAdmin: "Ir para o Painel do Google Workspace",
      partnerMethod: "Forma de contratação: ",
      renewalDate: "Data de renovação: ",
      activeLicenses: "Licenças ativas: ",
      pricePerLicense: "Valor por licença: ",
      priceResult: "R${price} usuário/mês",
    },

    usersGraphics: {
      title: "Gráfico de Usuários",
      inactive: "Inativos",
      suspended: "Suspensos",
      active: "Ativos",
      individual: "Individuais",
      couldBeGroups: "Poderiam ser grupos",
    },

    generalRow: {
      totalPrice: "R$ {price} mensais",
      totalPriceText:
        "Identificamos que sua empresa pode estar recebendo um custo desnecessário de até R${price} mensais nesta categoria.",
      withoutResearchPriceText:
        "Baseado nos valores do Plano Business Plus do Google Workspace, sua empresa pode ter um custo desnecessário de até R${price} mensais nesta categoria. Preencha os valores da licença ao lado para receber um diagnóstico apropriado.",
      neverLogged: "Nunca logado",
      usersKind: "Usuários",
    },

    inactiveUsers: {
      title: "Usuários inativos",
      tooltipText:
        "Há usuários inativos no seu Workspace, usuários inativos podem estar gerando um custo desnecessário para sua empresa. {researchAlert}",
      inactiveDays: "inativos há mais de {numberOfDays} dias",
    },

    suspendedUsers: {
      title: "Usuários suspensos",
      tooltipText:
        "Há usuários suspensos no seu Workspace, usuários suspensos podem estar gerando um custo desnecessário para sua empresa. {researchAlert}",
    },

    couldBeGroupsUsers: {
      title: "Usuários que poderiam ser grupos",
      tooltipText:
        "Identificamos usuários que poderiam ser grupos para otimizar seus custos. Ao utilizar grupo, você pode desfrutar de email gratuito e outras vantagens de organização de informação, além de seguir as boas praticas do Google. Para mais informações consulte nosso suporte.",
    },

    inactiveAppsUsers: {
      title: "Aplicações de usuários inativos",
      tooltipText:
        "Identificamos usuários inativos mantendo conexões em aplicações externas. Isso pode representar custos adicionais ou riscos de segurança para a sua empresa. Acesse o seu inventário para saber mais.",
      app: "Aplicação",
      apps: "Aplicações",
      accessInventory: "Acesse seu inventário",
    },

    withoutSignatureUsers: {
      title: "Usuários sem assinatura de e-mail",
      tooltipText:
        "Há colaboraderes sem assinaturas! Utilize agora assinaturas de e-mail profissionais da Conecta Suite para assim manter os dados e a identidade da marca da sua empresa sempre atualizados.",
      goToSignature: "Ir para assinaturas",
    },

    DNS: {
      title: "Qualidade dos domínios",
      tooltipText:
        "Alguns domínios precisam da sua atenção! Verifique o MX, SPF e DKIM de seus domínios. Em caso de dúvidas, entre em contato conosco através do nosso suporte via chat.",
      domains: "domínios",
      domain: "Domínio",
      knowMore: "Para entender mais sobre MX, SPF e DKIM de domínios: ",
      FAQ: "consulte o nosso FAQ",
      chatSupport:
        "Em caso de dúvidas, entre em contato conosco através do nosso suporte via chat.",
      helpArticle: "Ver artigo de ajuda",
      selector: "Seletor",
      editSelector: "Editar seletor",
      selectorInfo:
        "O seletor DKIM é um identificador único para um domínio de assinatura DKIM.",
      spamVerify: "Verificar se o domínio consta em alguma lista negra de Spam",
      useThe: "Utilize o",
      seeOur: "Veja nosso",
      knowMoreBlackLists:
        "para saber mais sobre Spam blacklists e como deixar seu domínio seguro no Google Workspace.",
      knowMoreDomains:
        "Para saber mais sobre as informações do seu domínio, consulte o",
      ignoredDomainText:
        "A verificação de qualidade foi automáticamente ignorada para este dominio pelo motivo do mesmo não realizar envio de e-mails.",
      ignoredVerify: "Verificação ignorada",
      googleMxRecordsNotFound:
        "O domínio precisa ter pelo menos um servidor de e-mail.",
      priorityMxRecordFound:
        "Domínio configurado com o servidor de e-mail de maior prioridade",
      allMxRecordsConfigured: "Todos os registros MX estão configurados",
      missingGoogleMxRecords: "Alguns registros MX não foram encontrados",
      googleStandardSpf: "SPF segue o padrão do Google",
      googleSpfNonStandard:
        "O SPF configurado não segue o padrão adotado pelo google",
      missingSpfDnsTxt: "SPF não configurado!",
      foundDkim: "DKIM configurado",
      missingDkim: "DKIM não configurado",
      missingDomainData:
        "Não foi possível encontrar um IP de servidor de nomes que exiba este domínio.",
      alertMessages: {
        BAD: "Alguns problemas críticos foram detectados na configuração deste domínio.",
        WARNING:
          "Alguns problemas não críticos foram detectados na configuração deste domínio.",
        OK: "As configurações de domínio, MX, SPF e DKIM foram configuradas com sucesso",
      },
    },

    without2faUsers: {
      title: "Usuários sem autenticação de dois fatores",
      tooltipText:
        "A segurança das contas de seus colaboradores pode ser aprimorada ao ativar a autenticação de dois fatores (2FA). Essa medida é essencial para fortalecer a proteção dos dados da sua empresa, adicionando uma camada adicional de segurança. Recomendamos que acesse as configurações de segurança no workspace e ative a 2FA,  assegurando assim a integridade e confidencialidade de seus dados e informações empresariais.",
    },

    googleDriveReports: {
      title: "Arquivos e pastas do Google Drive",
      tooltipText:
        "Arquivos e pastas em seu Drive corporativo podem estar sendo compartilhados externamente. Recomendamos que você verifique o relatório correspondente e assegure-se de estar no controle total de seus dados",
      files: "Arquivos",
    },

    googleTakeout: {
      title: "Garanta a proteção dos dados corporativos",
      tooltipText:
        "Os seus usuários podem ter acesso ao Google Takeout e exportar dados sensíveis de sua empresa. Verifique sua segurança!",
      knowHowToVerify: "Saiba como verificar aqui",
    },
    privacyCheckup: {
      title: "Assegure sua privacidade",
      tooltipText:
        "Revise suas principais configurações de privacidade com base nos serviços do Google que você mais usa.",
      knowHowToVerify: "Revise clicando aqui",
    },
  },

  onboardingNewUser: {
    welcomeOnboardingTitle: "Boas Vindas à criação de usuário",
    welcomeOnboardingSubtitle: "Antes de iniciar você deve saber",
    totalStepsLabel: "Este é um processo de 7 etapas.",
    progressAutomaticSaveLabel:
      "Você pode pausar esse processo a qualquer momento que o seu progresso será salvo.",
    requiredFirstStepLabel:
      "A primeira etapa é obrigatória para que as demais possam ser executadas.",
    suportHelperLabel:
      "Caso tenha dúvidas, poderá contatar nosso suporte utilizando os canais de atendimento.",
    recommendedDeviceLabel:
      "Para otimizar sua produtividade e desempenho, recomendamos considerar a utilização de um desktop para este fluxo.",
    userProfilePhoto: "Foto de perfil do usuário",
    uploadImage: "Fazer upload da imagem",
    loginAndPasswordManagement: "Gerenciamento de login e senha",
    loginAndPasswordManagementDescription: "Senha designada ao acesso do Gmail",
    loginAndPasswordManagementDescriptionWithControl:
      "Senha designada ao acesso do Gmail pelo Google e pelo Conecta Control",
    createPassword: "Criar uma senha",
    generateAStrongPassword: "Gerar automaticamente uma senha forte",
    password: "Senha",
    confirmPassword: "Confirmar senha",
    copyOnNextStep: "Você poderá ver e copiar a senha na etapa seguinte",
    requestPasswordChange: "Solicitar troca de senha no próximo login",
    createAndAdvance: "Criar e avançar",
    shifts: "Turnos",
    showingAllShifts: "Mostrando todos os turnos",
    searchShift: "Pesquisar turno",
    selectedShifts: "turnos selecionados",
    participants: "Participantes",
    login: "Início de sessão",
    loginTooltipText:
      "Valida se o usuário está em horário de trabalho via software de controle de ponto.",
    logout: "Encerramento de sessão",
    logoutTooltipText:
      'Os participantes do turno são deslogados no horário de fim de uma "regra de acesso" (periodo) do turno.',
    passwordPolicy: "Política de senha",
    passwordPolicyTooltipText:
      "Define em qual intervalo de tempo os usuários deverão alterar suas senhas pessoais.",
    allShiftsSelected: "Todos os turnos da tabela foram selecionados.",
    companyResources: "Recursos da empresa",
    companyCalendars: "Agendas da Empresa",
    finishOnboardingTitle:
      "Parabéns! O novo usuário já está pronto para fazer o primeiro login.",
    finishOnboardingSubtitle: "Agora você pode",
    guidanceForSendingAccessToUser:
      "Enviar os dados de login (e-mail e senha) para que o usuário faça o seu primeiro acesso.",
    rateExperience:
      "Avaliar a sua experiência com o nosso fluxo de criação de usuário.",
    all: "Todos os",
    shiftsSelectedsInPage: "turnos nesta página estão selecionados.",
    shiftsSelectedsInTable: "turnos da tabela estão selecionados.",
    selectAllShifts: "Selecionar todos os turnos",
    clearSelection: "Limpar seleção",
    resetSelection: "Redefinir seleção",
    changeEvery: "Troca a cada",
    days: "dias",
    pointControl: "Controle de ponto",
    nextPasswordChange: "Próxima troca de senha em",
    verifyPlanDrive:
      "Verifique se o plano de drives do Google Workspace está ativo para sua empresa.",
    companySharedDrivesNotFound:
      "Os drives compartilhados da empresa não foram encontrados",
    instructions: {
      userCreated: "O usuário já está criado no seu Painel Workspace!",
      reminderInfos:
        "Lembre-se de informar ao usuário, o e-mail e senha configurada para o login!",
      recommendations:
        "Recomendamos realizar a <strong>sincronização de usuários</strong> no Conecta Suite, para deixar a lista de usuários atualizada corretamente.",
      copyLoginInstructions: "Copiar informações de login",
      sendLoginInstructions: "Enviar instruções de login",
      sendInfoDescription:
        "Um e-mail será enviado com as credenciais de login diretamente para o endereço de e-mail do usuário.",
      templateEmail: {
        subjectEmail:
          "Você possui uma conta do Google Workspace para {company_name}",
        content:
          '<div style="font-family: inherit; text-align: start"><span>Olá,</span></div><div text-align: start"><br></div><div><span>Você possui uma nova Conta do Google Workspace na organização {company_name}.<br>Suas credenciais para realizar o primeiro acesso são:</span></div><div><span><strong>E-mail</strong></span><span>: {user_email}</span></div><div><span><strong>Senha</strong></span><span>: {user_password}</span></div><div><br></div><div><span>Utilize o </span><a href="https://mail.google.com/"><span><u>Gmail</u></span></a><span> para realizar o login&nbsp;</span></div><div><br></div><div><span>Após realizar o acesso, é importante redefinir a senha e garantir a segurança da sua conta.<br>Att,<br>Equipe Conecta Suite</span></div>',
      },
    },
    controlShift: {
      infoAboutThisStep:
        "Atribua o usuário a turnos de trabalho configurados no Conecta Control.",
      createShift:
        'Se necessário crie um novo turno e utilize o botão "Recarregar turnos" para atualizar a tabela abaixo.',
      noShifts:
        'Você ainda não possui turnos, clique em "Criar Turnos" e defina os horários de início e término de cada turno. ',
      hasNotAccessedControlYet: "Você ainda não acessou o Conecta Control.",
      hasNotAccessedControlYetDescription:
        "Acesse agora e aproveite os benefícios do controle de acesso dos usuários.",
    },
    fillRequiredFields:
      "Preencha os campos obrigatórios e clique em 'Criar e avançar'",
    selectOrCreateUser:
      "Selecione ou crie um usuário para avançar para a próxima etapa",
  },

  userInformations: {
    workPhone: "Telefone trabalho",
    office: "Cargo",
    signaturePhotoUrl: "URL da foto de assinatura",
    signaturePhotoUrlTooltipText:
      "Para evitar problemas com as imagens dos seus usuários, é recomendado que a imagem de <b>cada usuário seja uma URL de uma imagem compartilhada com todos no Google Drive</b>. Você também pode utilizar <b>imagens hospedadas pelo seu próprio servidor</b>.",
    userInfosDescription:
      "As informações pertinentes ao usúario serão salvas no perfil do mesmo no Google Workspace.",
    urlForScheduling: "URL para agendamento",
    customTag1: "Tag personalizada 1",
    customTag2: "Tag personalizada 2",
    customTagTooltipText:
      "Adicione uma informação personalizada para utilizar em seus modelos de assinatura",
    customTagPlaceholder1: "Conteúdo da tag personalizada 1",
    customTagPlaceholder2: "Conteúdo da tag personalizada 2",
    mobilePhone: "Telefone móvel",
    departament: "Departamento",
    shedulingHelpText:
      "Aprenda como criar um link de agendamento no Google Agenda <a target='_blank' style='text-decoration: none' href='https://youtu.be/lmJ5clI-bwo'><b>clicando aqui</b></a>.",
    title: "Editar informações do usuário",
    alertTagInfoText:
      'A informação é responsável por personalizar o e-mail do usuário da assinatura. Saiba mais em <a target="_blank" class="accent--text" href="https://conectasuite.com/perguntas-frequentes/conecta-sign/como-usar-tags-na-assinatura-de-e-mail/" >como usar a Informação na sua assinatura de e-mail.</a>',
    personalInformation: "Informações pessoais",
    employeelInformation: "Informações do funcionário",
    customInformations: "Informação personalizadas",
    nameAndLastName: "Nome + Sobrenome",
    editOnlyInWorkspace:
      "Esse campo só pode ser alterado na conta Google Workspace do usuário",
    addNewTag: "Adicionar nova tag",
    newCustomTag: "Nova tag personalizada",
    representation: "Representação",
    whatsapp: "WhatsApp",
    extension: "Ramal",
    userPhoto: "Foto do usuário",
    signPhotoAlertText:
      "Para evitar problemas com as imagens dos seus usuários, é recomendado que a imagem de <b >cada usuário seja uma URL de uma imagem compartilhada com todos no Google Drive</b >. Você também pode utilizar <b>imagens hospedadas pelo seu próprio servidor</b>.",
    upload: "Fazer Upload",
    addImage: "Adicionar imagem",
    selectImage: "Selecionar imagem",
    imageSavedOnDrive: "A imagem será salva no seu drive",
    deleteCustomTag: "Excluir tag personalizada",
    deleteCustomTagDescription: "Deseja confirmar a exclusão da tag",
    confirmInformationChanges: "Confirmar alterações das informações?",
    saveInWorkspaceInfoText:
      "Marque a caixa de seleção para salvar os dados do usuário no Google Workspace. Lembre-se, somente os dados abaixo podem ser atualizados no Google Workspace",
    saveInWorkspaceCheckboxText:
      "Gostaria de atualizar os dados do usuário no workspace?",
    reapplyConfirmationText:
      "Deseja reaplicar o modelo de assinatura com as novas informações para o usuário",
    reapplyEmailSignature: "Reaplicar assinatura de email",
    request: "Solicitar atualização das informações",
    emailSending:
      "Enviaremos um e-mail com um link exclusivo para cada usuário selecionado atualizar suas informações.",
    process: "O processo de atualização é bem simples:",
    emailReceipt:
      "Recebimento do e-mail: Cada usuário recebe um e-mail com um link personalizado.",
    linkAccess:
      "Acesso ao link: Ao clicar no link, o usuário é direcionado para atualizar seus dados.",
    deadline:
      "O prazo para realizar as alterações é de 7 dias a partir do recebimento do e-mail.",
    organizationUnitDescription:
      "Unidade organizacional do usuário configurado no Google Workspace",
    departamentDescription:
      "Departamento do usuário configurado no Google Workspace",
    officeDescription: "Cargo do usuário configurado no Google Workspace",
    function: "Função",
    functionDescription:
      "Se o usuário é administrador ou apenas membro do Workspace",
    usersShouldApply: "Usuário também deve aplicar a assinatura de e-mail",
    endDate: "Data de fim",
    emptyState: {
      signature: {
        noData: "Não encontramos registros de assinaturas de e-mail",
        cta: "Aplique sua primeira assinatura  clicando no botão abaixo!",
      },
      permissions: {
        noData: "Não encontramos permissões configuradas",
        cta: "Defina permissões de acesso clicando no botão abaixo!",
        action: "Gerenciar permissões",
      },
      vacation: {
        noData: "Não encontramos mensagens de férias configuradas",
        cta: "Aplique mensagem de férias clicando no botão abaixo!",
      },
      delegates: {
        noData: "Não encontramos registros de caixas delegadas",
        cta: "Delegue acesso a caixas de e-mail clicando no botão abaixo!",
        action: "Delegar acesso",
      },
      vacationControl: {
        noData: "Não encontramos usuários com férias ativas",
        cta: "Defina férias para um ou mais usuários clicando no botão abaixo!",
      },
      shifts: {
        noData: "Você ainda não configurou turnos de trabalho",
        cta: "Configure turnos de trabalho clicando no botão abaixo!",
        action: "Configurar turnos",
      },
    },
  },

  feedbackRating: {
    rating_error: "Selecione um nível de satisfação para enviar a pesquisa",
    leaveAComment: "Deixe um comentário",
    noRating: "Sem avaliação",
    veryDissatisfied: "Muito insatisfeito",
    dissatisfied: "Insatisfeito",
    indifferent: "Indiferente",
    satisfied: "Satisfeito",
    verySatisfied: "Muito satisfeito",
    sendSugestion: "Enviar sugestão",
    didNotWork: "Não funcionou",
    confusingInterface: "Interface confusa",
    slowPerformance: "Performance lenta",
    incorrectInformation: "Informações incorretas",
    incorrectSignature: "Assinatura incorreta",
    tooComplexModel: "Modelo muito complexo",
    moreOptionsNeeded: "Faltam mais opções",
    incorrectChanges: "Alterações incorretas",
    incorrectDelegation: "Delegação incorreta",
    emailNotFound: "E-mail não encontrado",
    incorrectEmail: "E-mail incorreto",
    why: "Porque",
    evaluationRegistered: "Tudo pronto, avaliação registrada!",
    rateOnMarketplace: "Avalie o aplicativo no marketplace",
    newAssessment: "Nova avaliação",
  },

  login: {
    slogan: `<span class="login-text-spotlight">Gestão</span> simplificada de <span class="login-text-spotlight">usuários corporativos</span>`,
    title: "Conecte gestão à tecnologia",
    accessDelegation: "Delegação de acesso",
    emailSignature: "Assinaturas de e-mail",
    muchMore: "e muito mais!",
    createAccountTerms: "Criando uma conta, você concorda com nossos",
    termsOfUse: "Termos de uso",
    privacyPolicy: "Política de privacidade",
    businessManagementSystem: "Sistema de gestão empresarial",
    riskReduction: "Redução de riscos",
    agileUserManagement: "Gestão ágil de usuários",
    moreSecurity: "Mais segurança",
  },

  dashboard: {
    conectaSign: {
      description:
        "Gerencie assinaturas de e-mail de forma simples e rápida. Crie modelos personalizados ou utilize nossos modelos prontos e edite em massa para todos os usuários, mantendo a identidade da sua empresa.",
      shortDescription:
        "Crie e edite assinaturas de e-mail e mensagens de férias para todos os usuários Google Workspace",
    },
    conectaTrack: {
      description:
        "Saiba automaticamente onde estão alocadas as horas de trabalho. Gere relatórios por períodos e saiba quem está online em tempo real.",
      shortDescription:
        "Monitore e registre automaticamente as atividades no Google Chrome",
    },
    conectaControl: {
      description:
        "Defina quem terá permissão para acessar as ferramentas do Google Workspace (G Suite), de qualquer lugar a qualquer hora. E quem só poderá acessar da empresa no horário de trabalho.",
      shortDescription:
        "Controle acessos através dos horários e locais de utilização do Google Workspace",
    },
    conectaSuite: {
      description: "Essa opção faz parte do painel administrativo da empresa!",
      shortDescription: "",
    },
    workspaceDiagnosis: {
      description:
        "Identifique potenciais problemas e melhorias para sua empresa",
      shortDescription: "",
    },
    manageAdmins: {
      description:
        "Gerencie administradores e dê acessos exclusivos para gestores",
      shortDescription: "",
    },
    reseller: {
      description:
        "Gerencie seus clientes e revise o faturamento através do painel administrativo",
      shortDescription: "Gerencie seus clientes pelo painel de parceiro",
    },
    companyProfile: {
      description: "Gerencie os dados de perfil da sua empresa!",
      shortDescription: "Atualize e visualize informações da empresa",
    },
    billing: {
      description: "Acesse suas assinaturas, serviços e histórico de cobrança!",
      shortDescription:
        "Gerencie os planos contratados da empresa e o histórico de cobrança",
    },
    users: {
      description:
        "Configure: mensagem de férias, delegação de acesso, enviar e-mail como e assinatura de e-mail!",
      shortDescription: "",
    },
    inventory: {
      description: "Gerencie o inventário digital da sua empresa!",
      shortDescription: "",
    },
    reports: {
      description: "Acompanhe relatórios de atividades dos usuários!",
      shortDescription: "",
    },
    contactUs: {
      description: "Podemos ajudar?",
      shortDescription: "",
    },
    manageEmailSignatures: "Gerenciar assinaturas de e-mail",
  },

  upgradeBanner: {
    trialTitleExpired: "Ops, seu período de teste terminou!",
    trialSubtitleExpired:
      "Adquira um plano para continuar aproveitando todas as funcionalidades dos produtos Conecta Suite!",
    trialTitlePeriod: "Está gostando do Conecta Suite?",
    trialSubitlePeriod:
      "Garanta já seu plano sem perder seus dias restantes de teste gratuito. Planos a partir de R$2,50/usuário/mês!",
    subtitleUpgradeTextDense:
      "Desbloqueie mais recursos. Explore, crie ou personalize modelos de assinatura e faça a aplicação em massa.",
    standardPlanAndUp: "Disponível a partir do plano Standard.",
    subtitleUpgradeTextNotDense:
      "Desbloqueie mais recursos, controle o acesso por tempo, local e navegador ao Workspace com Conecta Control",
    subtitleUpgradeTextHasBasic:
      "Mais recursos: aplique assinaturas e mensagem de férias em massa",
    subtitleUpgradeTextNotBasic:
      "Mais recursos: controle o acesso por tempo, local e navegador ao Workspace",
    upgradePlan: "Upgrade disponível para você",
  },

  functionalities: {
    licenseManager: "Gerenciar Licenças",
  },

  scopesAlert: {
    alertTitle: "Atenção!",
    emailMigration: {
      alertMessage:
        "Identificamos que existem espopos pendentes na aplicação Conecta Suite",
      resolveScopes:
        "O funcionamento adequado da migração de e-mails depende da autorização destes escopos.",
    },
    driveMigration: {
      resolveScopes:
        "O funcionamento adequado da transferência de arquivos depende da autorização destes escopos.",
    },
    alertMessage1:
      "Funcionalidades importantes podem deixar de funcionar corretamente devido a escopos pendentes na aplicação",
    alertMessage2:
      "Em caso de dúvidas, contate nossa equipe de suporte pelos canais de atendimento.",
    alertMessage3:
      "Entre em contato com o seu administrador do Google Workspace para solicitar a autorização dos escopos necessários.",
    alertMessage4:
      "A resolução <strong>URGENTE</strong> desses escopos é imprescindível para a OPERACIONALIDADE PLENA do sistema.",
    requestAdminToAcceptScopes:
      "Por favor, solicite que seu administrador aceite os escopos para utilizar esta funcionalidade.",
    requestToAcceptScopes:
      "Por favor, aceite os escopos no seu google workspace para utilizar esta funcionalidade.",
    alert:
      "A funcionalidade de {functionality} não está disponível devido a falta de escopos na aplicação.",
  },

  scheduleMeeting: {
    message:
      "Como você é novo por aqui, que tal agendar uma conversa com o nosso time de especialistas para esclarecer todas as suas dúvidas?",
    talkToOurSpecialists: "Fale com nossos especialistas",
    knowOurPlans: "Conheça nossos planos",
  },

  driveMigration: {
    title: "Transferência de arquivos",
    steps: {
      intro: {
        title: "Introdução",
        subtitle: "Vantagens da transferência de arquivos da Conecta Suite",
      },
      processes: {
        title: "Processos",
        subtitle:
          "Retome processos em andamento, inicie novos, ou visualize os concluídos",
      },
      start: {
        title: "Iniciar",
        subtitle:
          "Selecione o drive de um usuário e um grupo para o drive compartilhado.",
      },
      prepare: {
        title: "Preparar",
        subtitle:
          "Aguarde enquanto preparamos os dados para iniciar o processo.",
      },
      executing: {
        title: "Execução",
        subtitle:
          "Realize a transferência dos arquivos sozinho ou com outros colaboradores.",
      },
    },
    intro: {
      title: "Transfira arquivos de seus colaboradores",
      description:
        "Utilizando o Drive compartilhado de sua empresa, você pode reduzir o uso de armazenamento de seus colaboradores sem remover o acesso aos arquivos. Aqui você ainda realizará essa transferência seguindo as boas práticas do Google Workspace.",
      benefits: {
        1: "Reduz o uso de armazenamento dos colaboradores",
        2: "Tira o melhor proveito do armazenamento compartilhado",
        3: "Gerencia o acesso aos arquivos através de grupos do Workspace",
      },
    },
    processes: {
      title: "Processos",
      resume:
        'Caso deseje executar algum processo ainda não concluído, clique em "Retomar".',
      start: 'Clique em "Iniciar processo" para começar um novo.',
      callSupport:
        "Comunique nosso suporte para identificar uma possível falha.",
    },
    start: {
      title: "Iniciar processo",
      userTip: "Este é o usuário cujos arquivos serão transferidos.",
      groupTip:
        'Selecione um grupo do usuário e clique em "Preparar" a transferência de arquivos.',
      selectGroup: "Selecionar grupo",
      tryUser: "Tente selecionar um usuário antes",
      startTransfer: "Iniciar tranferência do Drive",
      userAccessDrive: "Usuários que terão acesso ao Drive compartilhado",
      hasExternalMember:
        "Atenção! Alguns membros deste grupo podem ser externos!",
      tooltipLabel:
        "Estes usuários receberão um e-mail informando sobre o acesso à este drive compartilhado e poderão ver e acessar os arquivos presentes nele.",
    },
    prepare: {
      loading: "Preparando lotes e criando pastas...",
      waitFinish:
        "Por favor, aguarde. Este processo pode demorar alguns minutos.",
      ready: "Tudo pronto!",
      filesAndFolders: "Número de arquivos e pastas",
      files: "Número de arquivos",
      batches: "Número de lotes",
      continue: "Clique em avançar para iniciar a transferência!",
      folders: "Número de pastas",
      create: "Pastas criadas",
    },
    executing: {
      title:
        "Transferência dos arquivos de <strong> {email} </strong> para o drive compartilhado <strong>{group}</strong>",
    },
    tips: {
      batches: {
        total: "Total de arquivos",
        finished: "Arquivos transferidos",
        missing: "Arquivos faltando",
        failures: "Arquivos com falhas",
      },
      workers: {
        ran: "Usuários que estavam executando",
        running: "Usuários executando",
        missing: "Usuários ausentes",
      },
    },
    errors: {
      title: "A transferência de arquivos falhou",
      createSharedDrive:
        "Você não possui drives compartilhados. Certifique-se de ter uma licença adequada do Google Workspace.",
      prepareSharedDrive:
        "Não foi possível preparar o drive compartilhado. Certifique-se de ter aceito os escopos.",
      listUserFiles:
        "Não foram encontrados arquivos do usuário selecionado. Certifique-se de que o usuário seja o proprietário dos arquivos em seu drive.",
      buildFolders:
        "Algum erro ocorreu. Tente novamente mais tarde, ou entre em contato com nosso suporte.",
    },
    startProcess: "Iniciar processo",
    unavailable: "Somente disponível para plano Security ou superior.",
    tempUnavailable: "Indisponível temporariamente. Em manutenção.",
    upsellInvitation:
      "A Migração de arquivos do Drive não está disponível para o seu plano. Conheça os planos disponíveis e faça um upgrade para fazer o backup dos arquivos com a transferência de dados.",
  },

  priceChangeWarning: {
    warning:
      "A partir do dia <strong>1º de julho de 2024</strong>, os preços dos planos Standard, Security e Enterprise da Conecta Suite serão reajustados conforme tabela disponível no site",
    noActionNeeded: "Nenhuma ação é necessária.",
    willingToHelp:
      "Estamos à disposição para esclarecer suas dúvidas pelo e-mail",
    thanks4BeeingClient: "Agradecemos por ser cliente Conecta Suite.",
    iUnderstood: "Eu entendi",
    subtitle: "Aviso sobre alteração no preço dos planos",
    priceAdjustment: "Reajuste no valor dos Planos",
  },

  emailMigration: {
    upsellInvitation:
      "A Migração de E-mails não está disponível para o seu plano. Conheça os planos disponíveis e faça um upgrade para fazer o backup dos e-mails com a transferência de dados.",
    title: "Migração de e-mails",
    subtitle:
      "Faça o backup dos e-mails do usuário para um grupo do Google Workspace. Apenas uma migração pode ser realizada por vez a nível empresarial.",
    steps: {
      intro: {
        title: "Introdução",
        subtitle: "Vantagens da migração de e-mails da Conecta Suite",
      },
      processes: {
        title: "Transferẽncias",
        subtitle:
          "Retome transferências de e-mail em andamento, inicie novas ou visualize as concluídas",
      },
      start: {
        title: "Iniciar",
        subtitle:
          "Selecione o e-mail de um usuário e o grupo de destino da migração.",
      },
      prepare: {
        title: "Preparar",
        subtitle:
          "Aguarde em enquanto preparamos os dados para iniciar o processo.",
      },
      executing: {
        title: "Execução",
        subtitle: "Realize a migração sozinho ou com outros colaboradores.",
      },
    },
    intro: {
      title: "Migre e-mails de seus colaboradores",
      description:
        "Migre e-mails de seus colaboradores para grupos do Workspace de forma segura, podendo iniciar ou pausar os processos a qualquer momento.",
      benefits: {
        1: "Reduz as perdas de informações por exclusão de e-mails importantes",
        2: "Tira proveito do uso de recursos dos grupos",
        3: "Gerencia o acesso aos e-mails através dos grupos do Workspace",
      },
    },
    start: {
      title: "Iniciar processo",
      userTip: "Selecione o usuário cujos e-mails serão migrados:",
      groupTip: "Selecione o grupo de destino:",
      selectGroup: "Selecionar grupo",
      tryUser: "Selecione um usuário antes",
      startDateTip: "Selecione a data de início dos e-mails:",
      selectStartDate: "Selecionar data de início",
      theEntirePeriod: "Todo o período",
      pastYear: "Último ano",
      past1Month: "Último mês",
      customPeriod: "Periodo personalizado",
      includeSpamAndTrashTip: "Deseja incluir os e-mails em spam e/ou no lixo?",
      includeSpamAndTrash: "Incluir e-mails em spam e/ou no lixo",
    },
    prepare: {
      loading: "Preparando lotes...",
      ready: "Tudo pronto!",
      files: "Número de e-mails",
      batches: "Número de lotes",
      continue: "Clique em avançar para iniciar a migração!",
    },
    executing: {
      title: "Migração dos e-mails de {email} para o grupo {group}",
    },
    tempUnavailable: "Indisponível temporariamente. Em manutenção.",
    includeTrashTip: "Deseja migrar os e-mails que estão na lixeira?",
    includeTrashLabel: "Incluir e-mails da lixeira",
    initMigration: "Iniciar migração",
    selectGroupTip: "Selecione o grupo no qual deseja migrar os e-mails de ",
    migrateEmailsFromDate: "Escolha o período para migração",
    dialog: {
      subtitle: "Migração de e-mails para grupos",
      alert: "Antes de iniciar o processo migração de e-mails, entenda:",
      timeToFinishInstruction:
        "A migração de emails pode levar de minutos a algumas horas para ser concluída.",
      closePageInstruction:
        "Você pode fechar esta página, pois o processo ocorrerá em segundo plano.",
      maxMailSizeTip: "Somente e-mails com até 25MB de tamanho serão migrados.",
      transferBackground:
        "A transferência será feita em segundo plano, para que você possa continuar com o processo de remoção de usuário sem interrupções.",
    },

    overlay: {
      migrationRunning:
        "Já existe uma tarefa de migração em andamento e não é possível iniciar migrações simultaneas a nível empresarial. Por favor, Aguarde a conclusão da migração atual e tente novamente.",
      missingScopesAlert:
        "A funcionalidade de migração de e-mails não está disponível devido a falta de escopos na aplicação.",
    },
    startMigrationDateDescription:
      "A migração ocorre de hoje até a data escolhida.",
    trashEmailTooltip: "O período de migração também é aplicado à lixeira.",
    emailsMigrated: "E-mails migrados",
    successfullyMigrated: "Migrados com sucesso",
    migrationFailure: "Falha ao migrar",
    groupEmailTransfer: "Transferência de e-mails para grupos",
  },

  offboardingUser: {
    welcomeOffboardingTitle: "Boas-vindas ao processo de remoção de usuário",
    welcomeOffboardingSubtitle: "Antes de iniciar você deve saber",
    totalStepsLabel: "Este é um processo de 7 etapas",
    progressAutomaticSaveLabel:
      "Você pode pausar esse processo a qualquer momento que o seu progresso será salvo.",
    suportHelperLabel:
      "Caso tenha dúvidas, poderá contatar nosso suporte utilizando os canais de atendimento.",
    selectTheUser: "Selecione o usuário",
    suspendTheUser: "Suspender o usuário",
    suspendTheUserCheckboxLabel:
      "O usuário escolhido será impedido de acessar a conta e será desconectado. Seus dados serão mantidos, mas ele não receberá e-mails, convites de calendário ou arquivos enquanto estiver suspenso. Os usuários suspensos podem ser restaurados, desde que não sejam excluídos.",
    changePassword: "Alterar senha",
    changePasswordDescription:
      "Redefina a senha do usuário para evitar que ele acesse a conta caso a suspensão seja removida futuramente.",
    removeFromControlShifts: "Remover dos turnos do control",
    passwordRuleMessage: "A senha deve ser maior que 8 e menor que 100",
    offboardingCompleted: "Processo de remoção de usuário finalizado",
    offboardingFailure: "Ocorreu um erro durante a finalização do processo",
    companyNoHasConectaControl: "Seu plano atual não possui o Conecta Control",
    actionsExecuted: "Ações executadas",
    aplicationsOfUser: "Aplicações conectadas ao usuário {user_email}",
    appsUserSubtitle:
      "Selecione as aplicações que deseja desconectar o usuário:",
    notFoundConnection: "Não foram encontradas conexões com essa aplicação.",
    appsNotDisconnected: "Algumas conexões não puderam ser deletadas: {data}",
    moreThan: "Há mais de 180 dias.",
    finishOfboardingTitle:
      "Parabéns! O processo de remoção de usuário foi finalizado.",
    removeLicenseAfterMigration:
      "A licença do usuário(a) {email} será removida após a transferência de dados.",
    notifyViaEmail:
      "Você será notificado quando as migrações forem concluídas.",
    removeUserFromSuite:
      "O usuário será removido do suite após a sincronização, mas você pode acessa-lo diretamente no google workspace.",
    transferEmailTitle: "Backup de e-mails com transferência de dados",
    transferEmailSubtitle:
      "Migrar emails de um usuário para um grupo do Google Workspace assegura que informações importantes permaneçam acessíveis à equipe, promovendo colaboração e transparência na redistribuição de responsabilidades.",
    transferDriveTitle: "Transferência de arquivos do Drive",
    transferDriveSubtitle:
      "Faça a transferência de arquivos do drive do usuário para um drive compartilhado para não perder os arquivos e informações que estão vinculados a ele.",
    selectGroupToTransfer:
      "Para iniciar a transferência, escolha um grupo ao qual o usuário está inserido.",
    transferArquives: "Transferir arquivos",
    licenseAssignTitle: "Gerenciamento de licenças",
    licenseAssignSubtitle:
      "Remover licenças de ex-funcionários evita gastos desnecessários com licenças não utilizadas, otimizando o orçamento da empresa.",
    licenseAssignAlert:
      "As licenças serão removidas somente após a conclusão da migração dos dados do Drive, E-mail e calendário.",
    noLicensesAssigned: "Nenhuma licença atribuída",
    in_queue: "Na fila",
    exectutionOrder: "Ordem de execução",
    hasTaskRunningAlert:
      "Já existe uma migração de e-mails em andamento, a migração de email do {email} será adicionada em uma fila e será executada após o termino da migração atual",
    migrationsInQTitle: "Migração de e-mail para grupo na fila",
    migrationsInQSubtitle:
      "Quando a migração atual acabar, o sistema iniciará a próxima migração",

    conecta_control_shifts: "Turnos do Conecta Control",
    remove_user_shifts:
      "Remova o usuário de todos os turnos do Conecta Control para impedir o seu acesso e mantenha a organização dos seus turnos.",
    update_password:
      "A nova senha do usuário será alterada no Conecta Control e no Google Workspace.",
    suspend_user: "Suspender usuário no google workspace",
    hasTransferenceRunningAlert:
      "Já existe uma tranferência de arquivos do Drive em andamento. Aguarde a transferência atual finalizar, para iniciar a próxima.",
  },

  dialogHasProduct: {
    yourCompany: "Sua empresa ainda não possui o {product}",
    yourCompanyPlan: "Este recurso está disponível a partir do Plano {plan}",
    knowBenefits:
      'Conheça todos os benefícios do {product} clicando em "Saiba mais"',
    knowPlanBenefits:
      'Conheça todos os benefícios do Plan {product} clicando em "Saiba mais"',
    trialExpired: "Período de TRIAL expirado",
    askAdmin: "Solicite ao administrador para liberar seu acesso!",
    notAdminOf: "Você não é administrador de ",
  },

  applySignature: {
    viewAppointments: "Ver agendamentos",
    goToUsers: "Ir para usuários",
    viewEmailSignatures: "Ver assinaturas de e-mail",

    signatureChangeRequestSent:
      "Solicitação de alteração na assinatura enviada por e-mail!",
    signatureChangeScheduledSuccess:
      "Alteração de assinatura de e-mail agendada com sucesso!",
    signatureAppliedSuccess: "Uhul! Assinatura de e-mail aplicada 🎉",

    exclusiveLinkSignatureChange:
      "Alteração de assinatura de e-mail por um link exclusivo",
    exclusiveLinkEmailDescription:
      "Cada usuário selecionado rececberá um e-mail com um link exclusivo de acesso para alterar sua assinatura de e-mail",
    fillOutInformation: "Preencher informações",
    exclusiveLinkInformationDescription:
      "O link exclusivo também permite que os usuários selecionados preencham suas respectivas informações cadastrais",

    signatureChangeScheduled: "Alteração de assinatura de e-mail agendada!",
    signatureScheduledDescription:
      "O Conecta Suite fará a alteração da assinatura de e-mail na data agendada.",
    signatureAppliedGmail: "A assinatura de e-mail foi aplicada no Gmail!",
    signatureDelayDescription:
      "A assinatura pode demorar até 15 minutos para ser visualizada.",
    restrictedUserAcceptance:
      "Os usuários definidos como restrito deverão aceitar a aplicação primeiro para terem suas assinaturas alteradas",
    learnMoreRestrictedUsers:
      'Saiba mais sobre usuários restritos <a href="https://www.youtube.com/watch?v=z5XSGuJFxPw" target="_blank">clicando aqui</a>.',

    viewEmailSignature: "Visualizar assinatura de e-mail",
    applyEmailSignatureDescription:
      "A assinatura de e-mail será aplicada para os usuários selecionados agora.",
    userWillapplyEmailSignatureDescription:
      "Ao confirmar esta ação, os e-mails serão enviados imediatamente aos usuários.",
    scheduleEmailSignatureDescription:
      "O agendamento pode ser cancelado a qualquer momento, antes da data definida para execução.",
    scheduleEmailSignatureApplication: "Agendar aplicação de assinatura",
    scheduleEmailSignatureApplicationDescription:
      "A assinatura de e-mail será aplicada na data selecionada.",

    usersApplyTheirOwnSignature:
      "Solicitar preenchimento dos dados e aplicação da assinatura",
    usersApplyTheirOwnSignatureDescription:
      "Os usuários selecionados receberão um link por e-mail para preencher suas informações no modelo de assinatura escolhido e, em seguida, aplicá-la ao seu e-mail.",

    applyEmailSignature: "Aplicar assinatura de e-mail",
    applyVacationMessage: "Aplicar mensagem de férias",
    selectSignatureModel: "Selecionar modelo de assinatura",
    customModels: "Modelos personalizados",
    defaultModels: "Modelos padrão",
    popular: "Popular",
    deleteModel: "Excluir modelo",
    editModel: "Editar modelo",
    duplicateModel: "Duplicar modelo",
    emptySignature: "Esta assinatura está vazia",
    missingInfoAlertText:
      "Um ou mais usuários selecionados não possuem algumas informações presentes neste modelo.",
    actionTextToCorrectInformation:
      "Clique sobre o usuário para corrigir as Informações ausentes.",
    editInformation: "Editar Informações de",
    applyModel: "Aplicar modelo",
    messageDelayToApply:
      "O modelo de assinatura pode levar até 10 minutos para ser efetivamente aplicado.",
    confirmApply:
      "Os usuários selecionados terão sua assinatura modificada. <strong> Deseja continuar? </strong>",
    infoScheduleApply:
      "A aplicação da assinatura somente será realizada na data selecionada no campo abaixo e possui um limite de 30 dias.",
    scheduleApplication: "Realizar agendamento da aplicação da assinatura",
    notifyUsers: "Notificar alteração de assinatura para todos os usuários",
    notifyUsersScheduled:
      "Notificar alteração de assinatura para todos os usuários após a execução da aplicação",
    infoNotifyUsers:
      "Usuários restritos receberão o e-mail de solicitação da aplicação de assinatura independente dessa opção ser selecionada ou não.",
    signatureModels: "Modelos de assinatura",
    viewInList: "Visualizar em lista",
    viewInGrid: "Visualizar em grade",
    goToApplication: "Ir para aplicação",
    defineModelToNewUsers: "Definir assinatura de e-mail para novos usuários",
    uncheckModelToNewUsers: "Desmarcar modelo para novos usuários",
    automaticApplication: "Aplicação automática",
    newModel: "Novo modelo",
    deleteSignatureModel: "Excluir modelo de assinatura",
    deleteSignatureModelWarning:
      "A exclusão não reflete na assinatura do gmail dos usuários abaixo, porém os mesmos ficarão sem um modelo de assinatura atribuido no Conecta Suite.",
    defineModelToNewUsersDescription:
      "Defina o <b >modelo de assinatura de e-mail que será aplicado automaticamente para novos usuários</b > do seu Workspace.",
    confirmModelToNewUsers:
      "Clique em <span class='font-weight-bold text-uppercase'>Confirmar</span> para definir este modelo para todos os novos usuários do seu Workspace.",
    newUser: "Novo usuário",
    signatureConfiguredForNewUsers:
      "Assinatura configurada para usuários novos",
    vacationModel: "Modelo de férias",
    applySignatureModelInstructions:
      "Escolha os usuários que deseja aplicar e clique em Aplicar assinatura de e-mail.",
    applyVacationModelInstructions:
      "Escolha os usuários que deseja aplicar e clique em Aplicar mensagem de férias.",
    usersShouldApply:
      "Pedir aos usuários para preencherem suas informações e apliquem esse modelo de assinatura",
    usersShouldApplyInfo:
      "Os usuários receberão um e-mail com um link para preencherem suas próprias informações, pré-visualizarem esse modelo de assinatura, e o aplicarem.",
    availableForPrimary: "Disponível somente para e-mails principais.",
    turnOnTour: "Ativar tutorial dos modelos",
    turnOffTour: "Desativar tutorial dos modelos",
  },
  usersPage: {
    primaryEmails: "E-mails principais",
    allEmails: "Todos os e-mails",
    secondarySearchBy: "Pesquisar por e-mail, usuário ou nome associado",
    primarySearchBy: "Pesquisar por nome ou e-mail principal",
    showing: "Mostrando",
    usersOnTableSelecteds: "usuários da tabela foram selecionados",
    usersOnPageSelecteds: "usuários nesta página estão selecionados",
    selectAllUsers: "Selecionar todos os usuários",
    selectAllUsersInSearch:
      "Selecionar todos os usuários que correspondem a esta pesquisa",
    clickOnTheUserToConfigureSendas:
      "Clique sobre o nome do usuário para configurar os e-mails secundários deste usuário",
  },
  filters: {
    filters: "Filtros",
    signatureModelApplied: "Modelo de assinatura aplicado",
    signatureModelAppliedDescription:
      "Último modelo de assinatura aplicado para o usuário",
    noSignatureModelSelected: "Nenhum modelo de assinatura selecionado",
    vacationMessageTemplateApplied: "Modelo de mensagem de férias aplicado",
    vacationMessageTemplateAppliedDescription:
      "Último modelo de mensagem de férias aplicado o usuário",
  },

  confirmUpdateTags: {
    successfull: "Atualização realizada com sucesso!",
    invalidLink: "Ops! O link acessado não é mais válido.",
    editAgain: "Editar Informações novamente",
    updateTags: "Atualizar Informações",
    previewAndApply: "Pré-visualizar e aplicar assinatura",
  },

  missingTags: {
    someAreInUsers:
      "Algumas Informações do modelo estão ausentes em usuários selecionados",
    allIsFilled: "Todas as Informações requeridas estão preenchidas",
    aUserAndATag:
      "O usuário {name} não possui a Informação <b>{tag}</b> preenchida",
    aUserAndTags: "O usuário {name} não possui a Informações preenchidas",
    usersAndATag:
      "{num} usuários selecionados não possuem uma Informação preenchida",
    usersAndTags:
      "{num} usuários selecionados não possuem algumas Informações preenchidas",
  },

  retry: {
    waitingGoogle: "Conectando ao Google. Por favor, aguarde um momento.",
    justInstalled:
      "Se você acabou de instalar o aplicativo, isso pode levar alguns minutos.",
    ensureScopes:
      'Certifique-se de ter concedido as permissões necessárias acessando o <a href="{link}" target="_blank">painel do Admin</a>.',
  },

  vacationApply: {
    overwriteWarning:
      "Alguns dos usuários selecionados já possuem mensagem de férias configurada. Aplicar uma nova configuração irá sobrescrever a mensagem aplicada anteriormente.",
    intro: {
      subtitle: "Saiba quais configurações podem ser feitas durante o fluxo",
    },
    model: {
      title: "Modelo de mensagem",
      subtitle: "Defina a mensagem que os usuários utilizarão",
      search: "Pesquisar por modelo em Todos os modelos",
      filter: "Filtrar por tipo de modelo",
      noModelFor: 'Não há modelos correspondentes para a pesquisa "{search}"',
      noCustomModelFor:
        'Não há modelos personalizados correspondentes para a pesquisa "{search}"',
      noDefaultModelFor:
        'Não há modelos padrão correspondentes para a pesquisa "{search}"',
      noResultFor:
        'Não há resultados correspondentes para a pesquisa "{search}"',
      searchCustom: "Pesquisar por modelo personalizado",
      searchDefault: "Pesquisar por modelo padrão",
      allModels: "Todos os modelos",
    },
    configure: {
      title: "Configurar mensagem",
      subtitle: "Escolha o período de duração, dentre outras configurações",
      pageTitle: "Configurações da mensagem de férias",
      description:
        "Configure o período em que a mensagem automática de férias deve funcionar e quem deve receber esta mensagem ao entrar em contato com o usuário de férias.",
      vacationPeriod: "Período de vigência das férias",
      userSchedule: "Configurações da agenda do usuário",
      addEvent: "Adicionar evento de férias na agenda do usuário",
      eventName: "Férias ⛱️",
      eventTitle: "Título do evento",
      selectEventName: "Escolher o nome do evento na agenda",
      restrictions: "Restrições de recebimento da resposta automática",
      restrictionsTooltip:
        "Restrinja o recebimento da mensagem automática de férias somente aos contatos do usuário e/ou somente aos usuários dentro do domínio da empresa",
      contactsOnly: "Enviar resposta somente aos contatos",
      companyOnly: "Enviar resposta somente aos usuários da empresa",
      forwarding:
        "Encaminhamento de e-mails recebidos durante período de férias",
      forwardingToUser: "Encaminhar e-mails para outro usuário",
      receiver: "Usuário que receberá os e-mails",
      selectReceiver: "Escolher usuário para receber os e-mails",
    },
    absenseSettings: {
      stepTitle: "Configurações de ausência",
      stepSubtitle:
        "Adicione o evento de férias e encaminhe e-mails durante a ausência",
      title: "Configurações de ausência durante as férias",
      subtitle:
        "Adicione um evento de férias na agenda ou encaminhe e-mails recebidos para outro usuário durante o período de férias",
      calendarSettings: "Configurações da agenda do usuário",
      addHolidayEvent: "Adicionar evento de férias na agenda do usuário",
    },

    startedVacationFlow: {
      title: "Ative a mensagem de férias no Google Workspace",
      subtitle:
        "Configure uma resposta automática de férias para notificar que o usuário não poderá responder de imediato.",
      setUpAVacationResponder:
        "Resposta automatica ao receber e-mails durante as férias.",
      forwardingReceived:
        "Encaminhamento de e-mails recebidos para outro usuário",
      addHoliday: "Adicione evento de férias a agenda do usuário",
      autoReply: "Defina quem receberá a resposta a automática",
    },

    save: {
      title: "Salvar alterações",
      subtitle: "Confirme e realize a aplicação das alterações",
      gridView: "Alternar para visualização em campo",
      groupView: "Alternar para visualização em grupo",
      noUserFor: 'Nenhum usuário encontrado para o valor "{search}"',
      searchUser: "Pesquisar por nome, departamento ou e-mail principal",
    },
    manageAccess: {
      title: "Gerenciar acesso",
      subtitle: "Gerencie o acesso durante as férias do usuário!",
      description:
        "Mantenha o controle total sobre o acesso dos seus colaboradores durante o período de férias usando o Conecta Control.",
      question: "Deseja bloquear o acesso dos seus colaboradores?",
      upsellInvitation:
        "O controle de acesso não está disponível para o seu plano. Conheça os planos disponíveis e faça um upgrade para fazer o controle de acesso dos usuários.",
      dontBlock: "Não bloquear",
      block: "Bloquear acesso nas férias",
      success:
        "O controle de acesso durante as férias dos usuários foi configurado com sucesso.",
      failure:
        "Não foi possível configurar o controle de acesso durante as férias dos usuários.",
    },
    concludedVacationFlow: {
      title: "Finalizar",
      subtitle: "Confira os detalhes",
      finishFlow: "Atribuição de férias concluída!",
      finishFlowSubtitle:
        "Os detalhes completos das férias configuradas estão listados abaixo",
      selectedModel: "Modelo escolhido",
      selectedPeriod: "Período definido",
      selectedUsers: "Usuários selecionados",
      calendarEvent: "Evento salvo na agenda",
      forwardingEmail: "Encaminhamento de e-mails para",
      templateApplied: "Modelo de resposta escolhido: {modelName}.",
      templateCustomApplied:
        "O modelo de resposta escolhido foi customizado por você.",
      rateExperience: "Avaliar a sua experiência com o nosso fluxo de férias",
    },
  },

  signAutomation: {
    activateTitle: "Ativar reaplicação automática de assinaturas",
    activateSubtitle:
      "Com a reativação automática de assinaturas cada usuário terá sua assinatura de e-mail reaplicada diariamente automaticamente.",
    alertControlPlan:
      "Para ativar a reaplicação automática de assinaturas é preciso contratar o Plano Security ou superior.",
    alertAutomationNotActive:
      "Ao ativar o Sign Automation, a reaplicação automática de assinaturas será implementada para todos os usuários da empresa que tiverem um modelo de assinatura aplicado.",
    alertAutomationActive:
      "<b>O Sign Automation está ativo.</b> Ao desativar, as assinaturas não serão mais reaplicadas automaticamente.",
    activate: "Ativar automação",
    deactivate: "Desativar automação",
    scheduledSignatures: "Aplicações de assinaturas agendadas",
    authorTooltip: "O usuário que executou a ação",
    errorTooltip:
      "Comunique nosso suporte para identificar uma possível falha.",
    signaturePreview: "Visualizar assinatura aplicada",
    previewSignatureName: "Visualizando assinatura {name}",
    cancelSchedule: "Cancelar agendamento",
    applyFor: "Aplicado para",
    successActivate: "Reaplicação automática DESATIVADA com sucesso",
    successDeactivate: "Reaplicação automática ATIVADA com sucesso",
    error: "Não foi possível alterar a reaplicação automática.",
    successOnCancel: "Sucesso no cancelamento do agendamento",
    errorOnCancel:
      "Não foi possível remover o agendamento. Contate o suporte da Conecta Suite.",
    cancelTitle: "Cancelar o agendamento",
    status: {
      created: "Falha ao agendar",
      scheduled: "Agendado",
      executed: "Executado",
      deleted: "Cancelado",
    },
  },
  tableHeaders: {
    users: "Usuários",
    department: "Departamento",
    lastLogin: "Último login",
    apps: "Aplicações",
    registrationDate: "Data do registro",
    scheduleDate: "Data agendada",
    situation: "Situação",
    author: "Ator",
    signature: "Assinatura",
    actions: "Ações",
  },

  reports: {
    reason: "Motivo",
    reasonNotRecorded: "O autor da ação não registrou um motivo.",
  },

  syncUsers: {
    syncUsers: "A sincronização de usuários está em andamento",
    synchronizingUsers: "Sincronizando usuários",
  },

  newDashboard: {
    meeting: "Agende uma chamada",
    meetingDescription: "Fale com um de nossos especialistas",
    hello: "Olá",

    goTocontrolCTA: "Acesse o Conecta Control",
    upsellToControlCTA: "Conheça o Conecta Control",
    controlCTADescription: "Configure turnos de maneira simples e eficiente!",

    dashCards: {
      title: {
        signature: "Assinatura de e-mail",
        vacationMessage: "Mensagem de férias",
        vacationActive: "Férias ativa",
        controlShifts: "Turnos de trabalho",
      },
      description: {
        signature: "Possuem assinaturas de e-mail ativas",
        vacationMessage: "Possuem mensagens de férias configuradas",
        vacationActive: "Estão ausentes com férias ativa",
        controlShifts: "Estão assinados a turnos de trabalho no Control",
      },
      subDescription: {
        signature: "<strong>{usersNumber}</strong> usuários não possuem",
        controlShifts: "<strong>{usersNumber}</strong> não estão em turnos",
      },
    },
    support: "Suporte",
    emptyStateAlert:
      "<strong>Atenção:</strong> Os dados mostrados abaixo são <strong>demonstrativos</strong>. Comece a realizar tarefas para <strong>substituí-los</strong> por dados reais.",
  },

  usersTableMetrics: {
    signature: "Assinaturas",
    permissions: "Permissões ativas",
    vacation: "Mensagem de férias",
    delegates: "E-mails delegados",
    vacationControl: "Controle de férias",
    shifts: "Turnos",
    signModel: "Modelo Assinatura",
    subtitle:
      "Utilize as abas abaixo para visualizar listas de colaboradores com diferentes configurações.",
  },

  permissions: {
    billing: "Faturamento",
    inventory: "Inventário digital",
    company_profile: "Perfil da Empresa",
    manage_admins: "Permissões de Usuário",
    reseller: "Conecta Partners",
    conecta_control: "Conecta Control",
    conecta_track: "Conecta Track",
    conecta_sign: "Aplicação em massa",
    conecta_suite: "Perfil do usuário",
    sign_automation: "Aplicação automática",
    reports: "Relatórios",
  },
  groupCreation: {
    intro: {
      subtitle: "Benefícios de se utilizar grupos do Google Workspace",
    },
    createGroup: {
      title: "Criar grupo",
      subtitle: "Defina nome, email e descrição do grupo.",
    },
    configureAccess: {
      title: "Configurar acesso",
      subtitle: "Defina permissões de acesso dos participantes.",
    },
    addParticipants: {
      title: "Adicionar participantes",
      subtitle: "Adicione os usuários que desejar ao grupo.",
    },
    configureSharedDrives: {
      title: "Configurar drives compartilhados",
      subtitle: "Adicione e configure os drives compartilhados do grupo.",
    },
  },
};
